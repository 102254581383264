const  mutations = {
    setPermisos(state, permisos) {
        state.permisos = permisos;
    },
    setBackgroundColor(state,val) {
        state.backgroundColor = val;
    },

};

export default mutations;