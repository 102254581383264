import axios from "@/axios/axios-api";

const actions = {
  async getSectores() {
    const response = await axios.get("api/sectores");
    return response.data.data;
  },
  async getSectoresById(context, payload) {
    const response = await axios.get(`api/sectores/${payload}`);
    return response.data.data;
  },

  async fetchUsuariosByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const usuarios = await axios.get(
        `api/estadosActas/autocomplete?input=${payload.input}`
      );
      return usuarios.data.data;
    }
  },
  async postSectores(context, payload) {
    const response = await axios.post("api/sectores", payload);
    return response;
  },
  async deleteSectores(context, payload) {
    const response = await axios.delete(`api/sectores/${payload.sectorId}`);
    return response;
  },
  async getDestinos() {
    const response = await axios.get("api/destinos");
    return response.data.data;
  },
  async getDestinosById(context, payload) {
    const response = await axios.get(`api/destinos/${payload}`);
    return response.data.data;
  },
  async postDestinos(context, payload) {
    const response = await axios.post("api/destinos", payload);
    return response;
  },
  async deleteDestinos(context, payload) {
    const response = await axios.delete(`api/destinos/${payload.id}`);
    return response;
  },
  async getTiposDeExpedientes() {
    const result = await axios.get("api/expedientes/tiposExpApp");
    return result.data.data;
  },
  async fetchTiposDeExpedientesHabilitados() {
    const result = await axios.get("api/taxonomy/tiposExpedientesHabilitados");
    return result.data.data;
  },
  async getTiposDeExpedientesById(context, payload) {
    const response = await axios.get(`api/expedientes/tiposExpApp/${payload}`);
    return response.data.data;
  },
  async saveTiposDeExpedientes(context, payload) {
    const response = await axios.post("api/expedientes/tiposExpApp", payload);
    return response;
  },
  async deleteTiposDeExpedientes(context, payload) {
    const response = await axios.delete(
      `api/expedientes/tiposExpApp/${payload.appExpConfigId}`
    );
    return response;
  },
  //GeneradoresExpedientes
  async getGeneradoresDeExpedientes() {
    const result = await axios.get("api/generadores");
    return result.data.data;
  },
  async getTipoGeneradoresDeExpedientes() {
    const result = await axios.get("api/taxonomy/tiposGeneradores");
    return result.data.data;
  },
  async getGeneradoresDeExpedientesById(context, payload) {
    const response = await axios.get(
      `api/generadores/generadorById/${payload}`
    );
    return response.data.data;
  },
  async saveGeneradoresDeExpedientes(context, payload) {
    const response = await axios.post("api/generadores", payload);
    return response;
  },
  async deleteGeneradoresDeExpedientes(context, payload) {
    const response = await axios.delete(`api/generadores/${payload.id}`);
    return response;
  },
  //TiposGeneradores
  async getTiposGeneradores() {
    const result = await axios.get("api/generadores/tiposGeneradores");
    return result.data.data;
  },
  async getTiposGeneradoresDeExpedientesById(context, payload) {
    const response = await axios.get(
      `api/generadores/tipoGeneradorById/${payload}`
    );
    return response.data.data;
  },
  async saveTiposGeneradoresDeExpedientes(context, payload) {
    const response = await axios.post("api/generadores/tipoGenerador", payload);
    return response;
  },
  async deleteTiposGeneradoresDeExpedientes(context, payload) {
    const response = await axios.delete(`api/generadores/tipoGenerador/${payload.id}`);
    return response;
  },
  async traerTiposExpedientes() {
    const result = await axios.get("api/expedientes/GetTiposExpedientes");
    return result.data.data;
  },
  async saveTramiteAdjuntoExpedientes(context, payload) {
    const response = await axios.post("api/expedientes/SaveTramiteAdjuntoForExpediente", payload);
    return response;
  },
  async saveAdicExpedientes(context, payload) {
    const response = await axios.post("api/expedientes/SaveDatoAdicExpediente", payload);
    return response;
  },
  async traerDatosAdicionalesTramites(context, payload) {
    const response = await axios.get(
      `api/expedientes/GetDatosAdicionalesTramites/${payload}`
    );
    return response.data.data;
  },
  async deleteDatoAdicional(context, payload) {
    const response = await axios.delete(`api/expedientes/DeleteDatoAdicionalTramite/${payload.appTramitesDatosAdicId}`);
    return response;
  },
};

export default actions;
