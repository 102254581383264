import aportes from './aportes';
import configuracion from './configuracion';
import devengamientos from './devengamientos';
import agenteCuenta from './agenteCuenta';
import caja from './caja';

export default({

    modules: {
        aportes,
        configuracion,
        devengamientos,
        agenteCuenta,
        caja
    }
});