<template>
  <v-app>
    <v-app-bar app clipped-left elevation="1">
      <v-app-bar-nav-icon @click="toggleDrawer()"></v-app-bar-nav-icon>
      <router-link to="/">
        <div class="d-flex align-center ml-4">
          <v-img
            alt="Gecros"
            class="shrink mr-2"
            contain
            :src="require('@/assets//img/gecros-logo.svg')"
            transition="scale-transition"
            width="44"
          />
          <h4 class="mb-0 hidden-xs-only">Gecros</h4>
        </div>
      </router-link>
      <div class="version">
        <a :href="`${lastVersion}${currentVersion}.pdf`" target="_blank">
          <p class="ml-2 mb-0">{{ currentVersion }}</p>
        </a>
      </div>
      <v-spacer></v-spacer>
        <v-autocomplete
          class="mt-3 mb-1 text-wrap"
          v-model="selectedOptionCard"
          hide-details
          :prepend-inner-icon="searchIcon"
          :append-icon="chevronIcon"
          autocomplete="menu-options-items"
          label="Buscar opción de menú"
          no-data-text="Inserte mínimo tres letras o presione la tecla ENTER para buscar."
          :items="tarjetasOpcionesCombo"
          item-text="titleWithoutAccent"
          :loading="isloading"
          item-value="id"
          return-object
          clearable
          @change="pushPage"
          @keydown.enter="loadDatos"
          :search-input.sync="searchText"
          dense
          single-line
          solo
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.descOption"
                  class=" ml-1"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      <v-spacer></v-spacer>
      <v-menu
        left
        bottom
        offset-y
        :close-on-content-click="false"
        v-if="userLogged"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="profileChip" v-bind="attrs" v-on="on">
            <span>
              {{ userLogged.nombre ? userLogged.nombre : "" }}
            </span>
            <v-avatar class="ml-2">
              <v-icon size="28px">mdi-account-circle</v-icon>
            </v-avatar>
          </v-chip>
        </template>
        <v-card id="profileCard" class="mx-auto" max-width="344">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="3" align-self="center">
                <v-avatar class="ml-2 mr-2">
                  <v-icon size="46px">mdi-account-circle</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="9" align-self="center">
                <h3 class="pl-2">
                  {{ userLogged.nombre ? userLogged.nombre : "" }}
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-1">
                <p>{{ userLogged.nombre ? userLogged.nombre : "" }}</p>
                <p v-if="userLogged.email && userLogged.email.lenght > 0">
                  {{ userLogged.email }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="actionCol">
                <v-list>
                  <v-list-item
                    link
                    v-if="userLogged.usuarioNombre !== 'gecrossuperusuario'"
                  >
                    <v-list-item-title @click="openChangePassword(true)"
                      >Cambiar contraseña</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="closeSession"
                      >Cerrar sesión</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :width="300"
      :mini-variant.sync="drawer"
      app
      clipped
      permanent
    >
      <div v-show="!drawer">
        <!-- Modulo actual -->
        <v-list
          v-if="menu && menu.currentMenu"
          subheader
          dense
          v-show="isNot404Page"
        >
          <v-subheader>{{
            activeMenu === "" ? currentMenu : activeMenu
          }}</v-subheader>
          <div v-for="(sub, i) in menu.currentMenu.children" :key="i" link>
            <div v-if="sub.children.length === 0">
              <v-list-item @click="goToPage(sub)">
                <v-list-item-title
                  class="primary--text text-wrap"
                  v-text="sub.title"
                ></v-list-item-title>
              </v-list-item>
            </div>
            <v-list-group
              :value="myValue"
              @click="showSubmenu(i)"
              v-if="sub.children.length > 0"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-title
                  class="mx-auto primary--text text-wrap"
                  v-text="sub.title"
                ></v-list-item-title>
              </template>
              <div v-for="(subchild, i) in sub.children" :key="i">
                <v-list-group
                  v-if="subchild.children.length > 0"
                  no-action
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text text-wrap"
                        v-text="subchild.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(nchild, i) in subchild.children"
                    :key="i"
                    link
                    @click="goToPage(nchild)"
                  >
                    <v-list-item-title
                      class="primary--text text-wrap"
                      v-text="nchild.title"
                    ></v-list-item-title>
                  </v-list-item>
                </v-list-group>
                <v-list-item link @click="goToPage(subchild)" v-else>
                  <v-list-item-title
                    class="primary--text text-wrap"
                    v-text="subchild.title"
                  ></v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
        </v-list>
        <v-divider class="mb-0" v-if="isNot404Page"></v-divider>
        <!-- Favoritos -->
        <div v-if="favourites && favourites.length > 0">
          <v-list dense subheader>
            <v-list-group prepend-icon="mdi-star">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="mx-auto primary--text text-wrap"
                    v-text="'Favoritos'"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(fav, i) in favourites"
                @click="goToPage(fav)"
                :key="i"
              >
                <v-list-item-title
                  class="primary--text text-wrap"
                  v-text="fav.title"
                ></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
        <v-divider class="mt-0" v-if="isNot404Page"></v-divider>
        <!-- Mas Modulos -->
      </div>
      <v-list subheader dense v-if="modulos">
        <v-subheader v-if="!drawer">Más módulos</v-subheader>
        <div v-for="(sub, i) in modulos" :key="i" link>
          <v-tooltip
            right
            :disabled="!drawer || mapIcons(sub).optionCode === ('' || null)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-group
                v-bind="attrs"
                v-on="on"
                :value="myValue"
                @click="showSubmenu(i)"
              >
                <template v-slot:activator>
                  <v-img
                    aspect-ratio="1"
                    :src="mapIcons(sub)"
                    contain
                    max-height="24"
                    max-width="24"
                  ></v-img>
                  <v-list-item-title
                    class="mx-auto primary--text text-wrap"
                    v-text="sub.title"
                  ></v-list-item-title>
                </template>
                <template v-slot:prependIcon>
                  <v-img
                    aspect-ratio="1"
                    :src="mapIcons(sub)"
                    contain
                    max-height="24"
                    max-width="24"
                  ></v-img>
                </template>
                <div v-for="(subchild, i) in sub.children" :key="i">
                  <v-list-group
                    v-if="subchild.children.length > 0"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          class="primary--text text-wrap"
                          v-text="subchild.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="(nchild, i) in subchild.children"
                      :key="i"
                      link
                      @click="goToPage(nchild)"
                    >
                      <v-list-item-title
                        class="primary--text text-wrap"
                        v-text="nchild.title"
                      ></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                  <v-list-item link @click="goToPage(subchild)" v-else>
                    <v-list-item-title
                      class="primary--text text-wrap"
                      v-text="subchild.title"
                    ></v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
            </template>
            <span>{{ sub.title }}</span>
          </v-tooltip>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <Loader
        v-if="appLoading.status"
        :text="appLoading.text"
        :opaque="appLoading.opaque"
      />
      <snack-bar />
      <transition mode="out-in" name="fade">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
      <Footer />
      <ChangePassword @close="openChangePassword"></ChangePassword>
    </v-main>
  </v-app>
</template>

<script>
import Loader from "@/deprecated/components/Loader/Loader";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { mapState } from "vuex";
import Footer from "@/components/layout/Footer";
import ChangePassword from "@/components/profile/ChangePassword";
import { eVersiones } from "@/utils/enums";
import mapIcons from "@/utils/helpers/mapIcon";
import isGecrosModule from "@/utils/helpers/isGecrosModule";
import SnackBar from "@/components/layout/SnackBar";
import enums from "@/utils/enums/index";
import axios from "axios";
import helper from "@/utils/helpers";

export default {
  name: "Layout",
  components: {
    Loader,
    Footer,
    ChangePassword,
    SnackBar,
    eVersiones
  },
  data() {
    return {
      enums: enums,
      helper: helper,
      model: true,
      myValue: false,
      drawer: false,
      isloading: false,
      selectedOptionCard: null,
      searchIcon: enums.icons.SEARCH,
      chevronIcon: enums.icons.CHEVRON_DOWN,
      tarjetasOpcionesCombo: [],
      tab: null,
      isNot404Page: true,
      currentMenu: null,
      searchText: "",
      openModalChangePassword: false,
      currentVersion: eVersiones.current,
      lastVersion: eVersiones.urlVersion,
      userCurrentlyLogged: true
    };
  },
  computed: {
    ...mapGetters({
      userLogged: "user/user",
      modulos: "user/modulos",
      activeMenu: "user/activeMenu",
      favourites: "user/favoritos",
      appLoading: "user/appLoading",
      ayudaOpened: "shared/ayudaOpened"
    }),
    ...mapActions({
      getGecrosVueModules: "shared/getGecrosVueModules"
    }),

    menu() {
      let currentMenu = this.modulos.find(
        item => item.title === this.activeMenu
      );
      return {
        currentMenu
      };
    }
  },
  beforeCreate() {
    this.$store.dispatch("user/setUser");
    this.$store.dispatch("user/getModulos");
  },
  mounted() {
    this.currentMenu = this.modulos.find(
      item => item.title === this.activeMenu
    );
    if (
      this.$router.currentRoute.name === "E404" ||
      (this.userLogged.usuarioNombre == "gecrossuperusuario" &&
        !isGecrosModule(this.modulos, this.currentMenu.title))
    ) {
      this.isNot404Page = false;
      return;
    }
    if (this.currentMenu) {
      this.isNot404Page = isGecrosModule(this.modulos, this.currentMenu.title);
    }
  },
  watch: {
    ayudaOpened(val) {
      val == true ? (this.drawer = true) : [];
    },
    drawer(val) {
      if (val) {
        this.myValue = false;
      }
    },
    searchText() {
      if (this.searchText?.length > 2) {
        this.searchText = this.removeAccents(this.searchText.toLowerCase());
        this.loadDatos();
      } else if (!this.searchText) {
        this.tarjetasOpcionesCombo = [];
      }
    }
  },
  methods: {
    ...mapActions({
      closeHelp: "shared/closeHelp"
    }),
    toggleDrawer() {
      this.drawer = !this.drawer;
      if (this.ayudaOpened) {
        this.closeHelp();
      }
    },
    showSubmenu(i) {
      this.myValue = i.value;
    },
    findFavs(obj) {
      obj.forEach(item => {
        if (item.favourite) {
          this.favourites.push(item);
        }
        if (item.children.length) {
          this.findFavs(item.children);
        }
      });
    },
    openChangePassword(open) {
      this.openModalChangePassword = open;
      this.$bus.$emit("openModal", this.openModalChangePassword);
    },
    async goToPage(menu) {
      // Si es superUsuario y quiere ir a pantalla de mvc, volver al login
      if (this.userLogged.usuarioNombre === "gecrossuperusuario") {
        const gecrosVueModules = await this.getGecrosVueModules;
        const isGecrosVueModule = gecrosVueModules.some(
          x => x.id == menu.optionId
        );

        if (!isGecrosVueModule) {
          this.closeSession();
          return;
        }
      }
      const moduleFound = this.modulos?.find(x => x.optionId === menu.moduleId);
      if (moduleFound) {
        this.$store.dispatch("user/changeActiveMenu", moduleFound.title);
      }

      //updetear como visitado
      const option = await axios.post(`api/menu/options/${menu.optionId}`);
      //por ultimo ingreso a la vista
      if (menu.framework === enums.frameworks.VUE) {
        // si hay acciones en localStorage, se pisan con las permitidas para esa opcion y usuario
        const actions = localStorage.getItem("allowedActions");
        if (actions) {
          localStorage.removeItem("allowedActions");
        }
        localStorage.setItem(
          "allowedActions",
          JSON.stringify(option.data?.children)
        );

        this.$router.push(`/${menu.path}`);
        this.drawer = true;
      } else {
        try {
          //me fijo si existe la cookie antes de ir al MVC
          if (document.cookie) {
            const cookie = this.helper.getCookie("gecrosgestioncookie");
            if (cookie) {
              this.$router.push({
                name: "mvc",
                params: { url: encodeURIComponent(menu.externalLink) }
              });
            } else {
              localStorage.removeItem("token");
              window.location.href = "/login";
            }
          } else {
            this.$router.push({
              name: "mvc",
              params: { url: encodeURIComponent(menu.externalLink) }
            });
          }
          this.drawer = true;
        } catch (error) {}
      }
    },
    mapIcons(item) {
      let icon;
      if (item != undefined) icon = mapIcons(item);
      return icon.img;
    },
    closeSession() {
      this.$store.dispatch("user/setLogoutMvc", () => {
        localStorage.removeItem("token");
        this.$router.push("/login");
      });
      this.userCurrentlyLogged = false;
    },
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async loadDatos() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (this.searchText?.length > 1) {
          let busqueda = this.removeAccents(this.searchText.toLowerCase());
          this.isloading = true;
          if (busqueda) {
            // si el usuario no esta logeado, no vuelve a llamar a la base de datos en user/getOpcionesSearch
            // esto sucede porque cuando el usuario desloggea, el watch de searchText pasa por este condicional
            // se rompe en gecrossuperusuario
            if (!this.userCurrentlyLogged) return;

            const response = await this.$store.dispatch(
              "user/getOpcionesSearch",
              busqueda
            );
            this.tarjetasOpcionesCombo = response;

            this.tarjetasOpcionesCombo.forEach(element => {
              // necesito filtrar las opciones con nombres iguales y asignarle un indice para que el autocomplete pueda diferenciarlos.
              let arrayTitlesIguales = this.tarjetasOpcionesCombo.filter(
                y => y.titleWithoutAccent == element.titleWithoutAccent
              );
              if (arrayTitlesIguales.length > 1) {
                let index = this.tarjetasOpcionesCombo.indexOf(element);
                this.tarjetasOpcionesCombo[
                  index
                ].titleWithoutAccent = `${element.titleWithoutAccent} ${index}`;
              }
            });
          }
          this.tarjetasOpcionesCombo.filter(
            element => !(element.path[0] == "/" || element.path[0] == ">")
          );
          this.isloading = false;
        }
      }, 500);
    },
    async pushPage() {
      // Si es superUsuario y quiere ir a pantalla de mvc, volver al login
      if (this.userLogged.usuarioNombre === "gecrossuperusuario") {
        const gecrosVueModules = await this.getGecrosVueModules;
        const isGecrosVueModule = gecrosVueModules.some(
          x => x.id == this.selectedOptionCard.optionId
        );
        if (!isGecrosVueModule) {
          this.closeSession();
          this.userLogged = null;
          return;
        }
      }
      if (this.selectedOptionCard) {
        let externalAction = `${window.location.origin}/redirto?id=${this.selectedOptionCard.path}`;
        if (this.selectedOptionCard.frameWork != 1) {
          const option = await axios.post(
            `api/menu/options/${this.selectedOptionCard.optionId}`
          );
          const actions = localStorage.getItem("allowedActions");
          if (actions) {
            localStorage.removeItem("allowedActions");
          }
          localStorage.setItem(
            "allowedActions",
            JSON.stringify(option.data?.children)
          );
          this.$router.push(`/${this.selectedOptionCard.path}`);
        } else {
          this.selectedOptionCard.externalLink = externalAction;
          this.$router.push({
            name: "mvc",
            params: {
              url: encodeURIComponent(this.selectedOptionCard.externalLink)
            }
          });
          this.$store.dispatch(
            "user/updateFrequencyByOptionCode",
            this.selectedOptionCard.optionCode
          );
        }
        this.selectedOptionCard = null;
        this.searchText = "";
      }
    }
  }
};
</script>

<style>
.version p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  padding-top: 6px;
}
a {
  text-decoration: none !important;
}
.v-main__wrap {
  background-color: #fafafa;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: transparent !important;
}
.profileChip {
  font-size: 16px !important;
  height: 48px !important;
}
.profileChip span {
  text-transform: capitalize;
}
#profileCard h3 {
  color: #195472;
  text-transform: capitalize;
  margin-left: 8px;
}
#profileCard .v-card__actions {
  padding: 0px;
}
#profileCard .actionCol {
  padding: 0px 16px 0px 16px;
}
#profileCard .v-list-item {
  min-height: 36px;
}
#profileCard p {
  margin: 0px 0px 4px;
  text-transform: capitalize;
}
#profileCard .v-divider {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-navigation-drawer {
  background: rgb(250, 250, 250);
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 43%,
    rgba(231, 231, 231, 1) 100%
  );
}
</style>
