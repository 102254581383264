import axios from "@/axios/axios-api";
const actions = {
  //Planes
  async getPlanes() {
    const response = await axios.get("api/planes/planes");
    return response.data.data;
  },
  async nuevoPlan(context, payload) {
    const response = await axios.post("api/planes/planes", payload);

    return response;
  },
  async deletePlan(context, payload) {
    const response = await axios.delete(`api/planes/planes/${payload.planId}`);
    return response;
  },
  async nuevaConfigPlan(context, payload) {
    const response = await axios.post("api/planes/configPlanes", payload);

    return response;
  },
  async deleteConfigPlan(context, payload) {
    const response = await axios.delete(
      `api/planes/configPlanes/${payload.planxivaId}`
    );
    return response;
  },
  async getTiposIva() {
    const response = await axios.get(`api/taxonomy/tipoIva`);
    return response.data;
  },
  async getConfigPlan(context, payload) {
    const response = await axios.get(`api/planes/configPlanes/${payload}`);
    return response.data.data;
  },
  async getPermisosConfigPlanes(context, payload) {
    const response = await axios.get(
      `api/planes/permisosConfigPlanes/${payload.optionCode}`
    );
    return response.data.data;
  },
  // Relacion convenios - empresas
  async getConvenioEmpresas() {
    const response = await axios.get("api/ObrasSociales/ConvenioEmpresas");
    return response.data.data;
  },
  async saveRelacionConvenioEmpresa(context, payload) {
    const response = await axios.post(
      "api/ObrasSociales/ConvenioEmpresas",
      payload
    );
    return response;
  }
};
export default actions;
