import axios from "@/axios/axios-api";
const actions = {
    async getAgrupacionGeografica() {
        const response = await axios.get("api/taxonomy/agrupacionGeografica");
        return response.data;
    },
    async getAgrupacionesPracticas() {
        const response = await axios.get("api/taxonomy/agrupacionesPracticas");
        return response.data.data;
    },
    async getAgrupacionEdad() {
        const response = await axios.get("api/taxonomy/agrupacionEdad");
        return response.data;
    },
    async getGrupoPorPractica(context, payload) {
        const response = await axios.get(`api/taxonomy/GruposByAgrupacionesPractica/${payload}`);
        return response.data.data;
    },
    async getParamsGralAfiliaciones() {
        const response = await axios.get("api/analytics/parametrosGralesAfiliaciones");
        return response.data.data;
    },
    async getParamsGralPrestaciones() {
        const response = await axios.get("api/analytics/parametrosGralesPrestaciones");
        return response.data.data;
    },
    async getParamsGralIngresos() {
        const response = await axios.get("api/analytics/parametrosGralesIngresos");
        return response.data.data;
    },
    async saveParamsGralAfiliaciones(context, payload) {
        const response = await axios.post("api/analytics/parametrosGralesAfiliaciones", payload);
        return response;
    },
    async saveParamsGralPrestaciones(context, payload) {
        const response = await axios.post("api/analytics/parametrosGralesPrestaciones", payload);
        return response;
    },
    async saveParamsGralIngresos(context, payload) {
        const response = await axios.post("api/analytics/parametrosGralesIngresos", payload);
        return response;
    },
    async getConfiguracionTableros() {
        const response = await axios.get("api/analytics/configuracionTableros");
        return response.data.data;
    },
    async getWebSiteOptionsByParent(context, payload) {
        const response = await axios.get(`api/taxonomy/webSiteOptionsByParent/${payload.parentOptionCode}`);
        return response.data.data;
    },
    async saveConfiguracionTableros(context, payload) {
        const response = await axios.post("api/analytics/configuracionTableros", payload);
        return response;
    },
    async saveOpcionDeMenu(context, payload) {
        const response = await axios.post("api/analytics/webOption", payload);
        return response;
    },
    async deleteConfiguracionTableroAnalytics(context, payload) {
        const response = await axios.delete(`api/analytics/configuracionTableros/${payload}`);
        return response;
    },
    async saveUrlApiAnalytics(context, payload) {
        const response = await axios.post("api/analytics/urlConexionAPI", payload);
        return response;
    },
    async proveAnalyticsConnection(context, payload) {
        const response = await axios.post("api/analytics/connection", payload);
        return response.data.data;
    },
    async getAnalyticsConnection() {
        const response = await axios.get("api/analytics/urlConexionAPI");
        return response.data;
    },
    async getOtrosTableros(context, payload) {
        const response = await axios.get(`api/analytics/otrosTableros/${payload}`);
        return response.data.data;
    },
    async runRecargaManual(context, payload) {
        const response = await axios.post("api/analytics/ejecutarTablero", payload);
        return response;
    },
    async getProgramacionModulos(context, payload) {
        const response = await axios.post("api/analytics/programacionModulosByModulo", payload);
        return response.data.data;
    },
    async saveProgramacionModulo(context, payload) {
        const response = await axios.post("api/analytics/updateProgramacionModulo", payload);
        return response;
    },
    async getLogEjecucionByModulo(context, payload) {
        const response = await axios.get(`api/analytics/logEjecucionTableroByModulo/${payload}`);
        return response.data.data;
    },
};
export default actions;