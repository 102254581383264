import axios from '@/axios/axios-api';

const  actions = {
  async getServidorById(context, payload){
    const response = await axios.get(`api/servidoresEmail/${payload}`);
    return response.data;
  },

  async fetchServidores() {
    const response = await axios.get('api/servidoresEmail');
    return response.data;
  },

  async saveServidorEmail(context, payload) {
    const response = await axios.post('api/servidoresEmail', payload);
    return response;
  },

  async sendTestEmail(context, payload){
    const response = await axios.post(`api/servidoresEmail/testEmail?esId=${payload.esId}&emailAddres=${payload.emailAddres}`);
    return response;
  },

  async deleteServidorEmail(context, payload) {
    const response = await axios.delete(`api/servidoresEmail/${payload}`);
    return response;
  },
  
  async fetchSecureSocketOptionEnum(){
    const response = await axios.get('api/servidoresEmail/secureSocketOptions');
    return response.data;
  }
}; 
export default actions;