const getters = {
  filtersTop: state => state.filtersTop,
  infoTable: state => state.infoTable,
  dataRow: state => state.dataRow,
  nuevasActasFiltersTop: state => state.nuevasActasFiltersTop,
  nuevasActasInfoTable: state => state.nuevasActasInfoTable,
  afiliadosByQuery: state => state.afiliadosByQuery,
  empresasByQuery: state => state.empresasByQuery
};

export default getters;