const mvcFramework = {
    embedded() {
        let mvcStyle = document.createElement('link');
        mvcStyle.type = 'text/css';
        mvcStyle.rel = 'stylesheet';
        mvcStyle.setAttribute('href', 'mvcstyle');
        document.head.appendChild(mvcStyle);
        let mvcSettings = document.createElement('script');
        mvcSettings.innerHTML = `var fullPath = '${'/'}', ua = 1;`;
        document.head.appendChild(mvcSettings);
        let mvcScript = document.createElement('script');
        mvcScript.setAttribute('src', '/bundles/plugins');
        document.head.appendChild(mvcScript);
        let customMvcScript = document.createElement('script');
        customMvcScript.setAttribute('src', '/bundles/custom');
        document.head.appendChild(customMvcScript);
    }
};

export default {
    install(Vue, name = '$mvcf') {
        Object.defineProperty(Vue.prototype, name, { value: mvcFramework });
    }
};