import axios from "@/axios/axios-api";

const actions = {
  async getLogSincronizacionGeclisaByParams(context, payload) {
    const response = await axios.post("api/logSincronizacionGeclisa/logSincronizacionGeclisa", payload);
    return response.data.data;
  }
};

export default actions;
