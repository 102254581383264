import axios from "@/axios/axios-api";
import messages from "@/utils/enums/messages";

const actions = {
  async getPrestadorGeocodeAddress(context, payload) {
    const { calle, numero, localidad, locId, provincia, pais } = payload;
    const response = await axios.get(
      `api/prestadores/geocodeAddress?calle=${calle}&numero=${numero}&localidad=${localidad}&locId=${locId}&provincia=${provincia}&pais=${pais}`
    );
    return response.data.data;
  },

  async getOrigenGeocodeAddress(context, payload) {
    const { domicilio, localidad, locId, provincia, pais } = payload;
    const response = await axios.get(
      `api/origenes/geocodeAddress?domicilio=${domicilio}&localidad=${localidad}&locId=${locId}&provincia=${provincia}&pais=${pais}`
    );
    return response.data.data;
  },

  async getPrestadorGeoLoc(context, payload) {
    const response = await axios.get(
      `api/prestadores/obtenerDomicilio?preId=${payload.preId}&tipoRadicId=${payload.tipoRadicId}`
    );
    return response.data.data;
  },

  async getOrigenGeoLoc(context, payload) {
    const response = await axios.get(
      `api/origenes/obtenerDomicilio?oriId=${payload.oriId}`
    );
    return response.data.data;
  },

  async getPlanesByOsId(context, payload) {
    const planes = await axios.get(`api/planes/osId?osId=${payload}`);
    return planes.data.data;
  },

  async getConfiguracionPrestadoresFiltersTop(context) {
    const conveniosItems = await axios.get("api/obrassociales");
    const especialidadesItems = await axios.get("api/taxonomy/especialidades");
    const localidadesItems = await axios.get("api/taxonomy/localidades");

    let moduleLoaded = localStorage.getItem("moduleLoaded");
    moduleLoaded = JSON.parse(moduleLoaded);

    const convenios = {
      model: null,
      items: conveniosItems.data.data
    };

    const especialidades = {
      model: null,
      items: especialidadesItems.data.data
    };

    const localidades = {
      model: null,
      items: localidadesItems.data.data
    };

    const filtersTop = { convenios, especialidades, localidades };

    context.commit("setConfiguracionPrestadoresFiltersTop", filtersTop);
  },

  async getConfiguracionCartillaInfoTable(context, payload) {
    const result = await axios.post(
      "api/prestadores/cartilla",
      payload.bodyParams
    );
    return result.data.result;
  },
  async fetchPrestadoresByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const prestadores = await axios.get(
        `api/prestadores/autocompleteCartilla?nombrePrestador=${payload.input}`
      );
      context.commit("setPrestadoresByQuery", prestadores.data.data);
    }
  },
  async fetchOrigenesByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const origenes = await axios.get(
        `api/origenes/autocompleteCartilla?nombreOrigen=${payload.input}`
      );
      context.commit("setOrigenesByQuery", origenes.data.data);
    }
  },

  async getPrestadorById(context, payload) {
    const prest = await axios.get(
      `api/prestadores/datosCartillaById?preId=${payload.prestadorId}&oriId=${payload.origenId}`
    );

    return prest.data.data;
  },
  async getProvincias(context, payload) {
    const response = await axios.get("api/provincias");
    return response.data.data;
  },
  async getLocalidadesByProvId(context, provId) {
    const localidades = await axios.get(
      `api/taxonomy/localidadByProvId?provId=${provId}`
    );
    return localidades.data.data;
  },
  async updatePrestadorAddress(context, payload) {
    const url = "api/prestadores/datosDomicilio";
    const response = await axios.put(url, payload);
    return response;
  },

  async updateOrigenAddress(context, payload) {
    const url = "api/origenes/datosDomicilio";
    const response = await axios.put(url, payload);
    return response;
  },

  async updatePrestadores(context, prestadorDTO) {
    let response = await axios.put(
      "api/prestadores/datosCartilla",
      prestadorDTO
    );
    context.commit(
      "user/setAlertMessage",
      {
        type: response.data > 0 ? "success" : "error",
        message:
          response.data > 0 ? messages.UPDATE_SUCCESS : messages.UPDATE_ERROR
      },
      { root: true }
    );
    return response.data > 0;
  },
  async putMassiveGeolocation() {
    const response = await axios.put(
      "api/AppBenefPrestadores/GeographyLocation"
    );
    return response;
  },
  async getTipoPrestadores(context, payload) {
    const response = await axios.get(
      "api/prestadores/tiposPrestadores",
      payload
    );
    return response.data.data;
  },
  async saveTipoPrestador(context, payload) {
    const response = await axios.post(
      "api/prestadores/tiposPrestadores",
      payload
    );
    return response;
  },

  async getPrestadoresEspecialidades() {
    const response = await axios.get(`api/prestadores/especialidades`);
    return response.data.data;
  },
  async saveEspecialidad(context, payload) {
    const response = await axios.post(
      "api/prestadores/especialidades",
      payload
    );
    return response;
  },
  async deleteTipoPrestador(context, payload) {
    const response = await axios.delete(
      `api/prestadores/tiposPrestadores/${payload}`
    );
    return response;
  },
  async getCodigosPrescribibles(context, payload) {
    const response = await axios.post(
      "api/prestadores/getCodPrescripTipoPrestador",
      payload
    );
    return response.data.data;
  },
  async getPermisosCodigosPresc(context, payload) {
    const response = await axios.get(
      `api/prestadores/tiposPrestadores/codPrescripTipoPrestadorPermisos/${payload}`
    );
    return response.data.data;
  },
  async deleteEspecialidad(context, payload) {
    const response = await axios.delete(
      `api/prestadores/especialidades/${payload}`
    );
    return response;
  },
  async postCodigoPrescribible(context, payload) {
    const response = await axios.post(
      "api/prestadores/CodPrescripTipoPrestador",
      payload
    );
    return response;
  },
  async deleteCodigoPrescribible(context, payload) {
    const response = await axios.delete(
      `api/prestadores/codPrescripTipoPrestador/${payload}`
    );
    return response;
  },

  async getConsultaProcesos(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/ConsultaProcesos",
      payload
    );
    return response.data.data;
  },

  // Anular proceso de actualizacion de facturacion

  async anularProcesoActualizFactura(context, payload) {
    const response = await axios.delete(
      `api/TablasFacturacion/AnulaProceso/${payload.procAumTablaFacId}?validaTablasModificadas=${payload.validaTablasModificadas}`
    );
    return response.data.data;
  },
  async fetchOrigenes() {
    const response = await axios.get(`api/taxonomy/Origenes`);
    return response.data.data;
  },
  async fetchTablasFacturacionByFilters(context, payload) {
    const response = await axios.post("api/TablasFacturacion", payload);
    return response;
  },
  async getTablaFacturacionDetalle(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/detalleTabla/${payload}`
    );
    return response.data.data;
  },
  async getPropietariosTabla(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/PropietariosTabla/${payload}`
    );
    return response.data.data;
  },
  async getTablaFacturacionAranceles(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/Aranceles/${payload}`
    );
    return response.data.data;
  },
  async getNombreCtaFacturacion(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/NombreCtaFacturacion/${payload}`
    );
    return response.data.data;
  },
  async getDetalleProceso(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/DetalleProceso/${payload}`
    );
    return response.data.data;
  },
  async getPracticaById(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/DetalleCtaFac/${payload}`
    );
    return response.data.data;
  },
  async getDetalleTablasAfectadasxProceso(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/GetDetalleTablasAfectadasxProceso/${payload}`
    );
    return response.data.data;
  },

  async getParametrosGeneralesTablasFac() {
    const response = await axios.get(
      "api/TablasFacturacion/GetParametrosGenerales"
    );
    return response.data.data;
  },
  async saveProcesoAumentoTablasFacturacion(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/SaveProcesoAumento",
      payload
    );

    return response;
  },
  async saveParametrosGeneralesTablasFac(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/ParametrosGenerales",
      payload
    );

    return response;
  },

  async fetchAgentePagoByQuery(context, payload) {
    const response = await axios.get(
      `api/Prestadores/AgentePagoByQuery/?codigo=${payload.codigo}&nombre=${payload.nombre}&cuit=${payload.cuit}`
    );
    return response.data.data;
  },

  async fetchOrigenesByQuery(context, payload) {
    if (payload.input) {
      const origenes = await axios.get(
        `api/origenes/autocompleteCartilla?nombreOrigen=${payload.input}`
      );
      return origenes.data.data;
    }
  },
  async fetchOrigenesNombreByQuery(context, payload) {
    if (payload.input) {
      const origenes = await axios.get(
        `api/origenes/autocompleteNombreOrigen?nombreOrigen=${payload.input}`
      );
      return origenes.data.data;
    }
  },
  async getAgentePagoByQuery(context, payload) {
    if (payload.input) {
      const agentes = await axios.get(
        `api/prestadores/AgentePagoByQuery?nombreAgente=${payload.input}`
      );
      return agentes.data.data;
    }
  },
  async getTablasFacturacion(context, payload) {
    if (payload.input) {
      const response = await axios.get(
        `api/Taxonomy/TablasFacturacion?param=${payload.input}`
      );
      return response.data;
    }
  },
  async getNomFarmacia() {
    const response = await axios.get("api/Taxonomy/NomencladoresFarmacia");
    return response.data.data;
  },
  async getTablasFacturacionConsulta(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/ConsultaTablas",
      payload
    );
    return response;
  },
  async deleteTablaFacturacion(context, payload) {
    const response = await axios.delete(
      `api/TablasFacturacion/deleteTablaFac/${payload}`
    );
    return response;
  },
  async exportTablaFacturacionImportacion(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/ExportaTablaFacturacionImportacion/${payload}`
    );
    return response.data.data;
  },
  async saveTablaFact(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/saveTablaFacturacion",
      payload
    );

    return response;
  },
  async heredarTablaFac(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/HeredarTablaFacturacion",
      payload
    );
    return response.data.data;
  },
  async getNomencladores() {
    const response = await axios.get("api/taxonomy/nomencladores");
    return response.data.data;
  },
  async importaTablaFacturacion(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/ImportTablaFacturacion",
      payload
    );
    return response;
  },
  async fetchUnidadesAranceles() {
    const response = await axios.get("api/taxonomy/UnidadesAranceles");
    return response.data.data;
  },
  async saveNuevaCtaFac(context, payload) {
    const response = await axios.post(
      `api/TablasFacturacion/SaveCtaFac/${payload.tabId}`,
      payload.ctaFac
    );
    return response.data.data;
  },
  async deleteCuentaFac(context, payload) {
    const response = await axios.delete(
      `api/TablasFacturacion/DeleteCtaFac/${payload}`
    );
    return response;
  },
  async saveNuevoArancel(context, payload) {
    const response = await axios.post(
      `api/TablasFacturacion/SaveArancel/${payload.tabId}`,
      payload.ctaFac
    );
    return response;
  },
  async deleteAran(context, payload) {
    const response = await axios.delete(
      `api/TablasFacturacion/DeleteArancel/${payload}`
    );
    return response;
  },
  async fetchTiposProcesosFormatosById(context, payload) {
    const response = await axios.get(
      `api/taxonomy/TiposProcesosFormatos/${payload}`
    );
    return response.data.data;
  },
  async importGruposEntidadesEfectoras(context, payload) {
    const response = await axios.post(
      "api/Prestadores/ImportGruposEntidadesEfectoras",
      payload
    );
    return response;
  },
  async saveAgrupacion(context, payload) {
    const response = await axios.post(
      "api/prestadores/SaveAgrupacion",
      payload
    );
    return response;
  },
  async deleteAgrupacion(context, payload) {
    const response = await axios.delete(
      `api/prestadores/DeleteAgrupacion/${payload}`
    );
    return response;
  },
  async getAgrupaciones() {
    const response = await axios.get("api/taxonomy/Agrupaciones");
    return response.data.data;
  },
  async getGruposByAgrupaciones(context, payload) {
    const response = await axios.get(
      `api/taxonomy/GruposByAgrupaciones/${payload}`
    );
    return response.data.data;
  },
  async deleteGrupoEntidad(context, payload) {
    const response = await axios.delete(
      `api/prestadores/DeleteGruposEntidadesEfectoras/${payload}`
    );
    return response;
  },
  async getGruposEntidadesEfectoras(context, payload) {
    const response = await axios.post(
      "api/prestadores/GruposEntidadesEfectoras",
      payload
    );
    return response.data.data;
  },
  async getOrigenesByGrupos(context, payload) {
    const response = await axios.post("api/taxonomy/OrigenesByGrupo", payload);
    return response.data.data;
  },
  async getOrigenesByFilters(context, payload) {
    const response = await axios.post(
      "api/origenes/GetOrigenesByFilters",
      payload
    );
    return response.data;
  },
  async fetchProcesosAsignacionTablasFacturacion() {
    const response = await axios.get(
      "api/TablasFacturacion/ProcesosAsignacionTablas"
    );
    return response.data.data;
  },
  async getProcesoAsignacionTablasById(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/ProcesoAsignacionTablasDeta/${payload}`
    );
    return response.data.data;
  },
  async getPlanesByConvenioAndVigencia(context, payload) {
    const response = await axios.post(
      `api/taxonomy/planesByConvenio?vigencia=${payload.vigencia}`,
      payload.conveniosIds
    );
    return response.data.data;
  },

  async getAsignaciones(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/GetAsignacionesByFilters",
      payload
    );
    return response.data.data;
  },
  async saveGrupoEntidad(context, payload) {
    const response = await axios.post(
      "api/prestadores/SaveGruposEntidadesEfectoras",
      payload
    );
    return response;
  },
  async saveAsignacionTablaFac(context, payload) {
    const response = await axios.post(
      "api/TablasFacturacion/SaveAsignacionTablaFacturacion",
      payload
    );
    return response.data.data;
  },
  async getUnidadesByTabla(context, payload) {
    const response = await axios.get(
      `api/TablasFacturacion/UnidadesTabla/${payload}`
    );
    return response.data.data;
  },
  async anularProcesoAsig(context, payload) {
    const response = await axios.delete(
      `api/TablasFacturacion/AnulaProcesoAsignacionTablaFac/${payload.procAsignTablaFacId}`
    );
    return response.data.data;
  },
  async getProcesosActualizacionAlfaBeta(context, payload) {
    const response = await axios.post(
      "api/AlfaBeta/ProcesosActualizacion",
      payload
    );
    return response.data.data;
  },
  async anulaProcesoActualzacionAlfaBeta(context, payload) {
    const response = await axios.delete(
      `api/AlfaBeta/AnularProceso/${payload.procId}`
    );
    return response;
  },
  async getConfigActualizacionAB() {
    const response = await axios.get(
      "api/AlfaBeta/ConfigActualizaAB"
    );
    return response.data.data;
  },
  async saveConfigActualizacionAB(context, payload) {
    const response = await axios.post(
      "api/AlfaBeta/ConfigActualizaAB",
      payload
    );
    return response.data.data;
  },
  async importArchivoProveedoresGral(context, payload) {
    const response = await axios.post(
      "api/proveedores/ImportarArchivoProveedoresGral",
      payload
    );
    return response.data.data;
  },
  async validarArchivos(context, payload) {
    const response = await axios.post(
      "api/proveedores/ValidarArchivosAImportarProveedores",
      payload
    );
    return response.data.data;
  },
  async getImportacionesFactProv(context, payload) {
    const response = await axios.post(
      "api/proveedores/GetImportacionesFacturasProv",
      payload
    );
    return response.data.data;
  },
  async anulaProcesoImport(context, payload) {
    const response = await axios.delete(
      `api/Proveedores/DeleteProcesoImportacionFactProv/${payload.procId}`
    );
    return response.data.data;
  },
  async procesarArchivoActualizacionAlfaBeta(context, payload) {
    const response = await axios.post(
      "api/AlfaBeta/ProcesarArchivo",
      payload
    );
    return response;
  },
  async grabarProcesoActualizacionAlfaBeta(context, payload) {
    const response = await axios.post(
      "api/AlfaBeta/GrabarProceso",
      payload
    );
    return response;
  },
};
export default actions;
