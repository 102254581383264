import axios from "@/axios/axios-api";
import enums from "@/utils/enums/index.js";

const actions = {
  async getPermisos(context, groupId) {
    let dataResponse = await axios.post(`api/menu/optionsByGroupId/${groupId}`);
    let permisos = mapPermisos(dataResponse.data.data);

    let seleccionados = selected(dataResponse.data.data);
    context.commit("setPermisos", { items: permisos, selected: seleccionados });
  },
  async grabarPermisos(context, grabarPermisosDTO) {
    let dataResponse = await axios.post(
      "api/menu/grabarpermisos",
      grabarPermisosDTO
    );
    context.commit(
      "user/setAlertMessage",
      {
        type: dataResponse.data > 0 ? "success" : "error",
        message:
          dataResponse.data > 0
            ? enums.messages.UPDATE_SUCCESS
            : enums.messages.SYSTEM_ERROR
      },
      { root: true }
    );
  },
  async saveAyuda(context, payload) {
    const response = await axios.post("api/sysUser/ayudas", payload);
    return response;
  },
  async getAyuda(context, payload) {
    const response = await axios.get(
      `api/sysUser/ayudas?optionCode=${payload}`
    );
    return response.data.data;
  },
  async getPublicSettings(context) {
    let dataResponse = await axios.get("api/ParametrosGenerales/color-login");
    context.commit("setBackgroundColor", dataResponse.data.hexColor);
  },
  async setConfigAvanzada(context, payload) {
    const response = await axios.post(
      "api/ParametrosGenerales/color-login",
      payload
    );
    return response;
  },
  async getConfigAvanzada(context, payload) {
    const response = await axios.get(
      "api/ParametrosGenerales/color-login",
      payload
    );
    return response.data;
  },
  async getCustomLogo() {
    const response = await axios.get("api/parametrosGenerales/get-img-diag");
    return response;
  },
  async saveCustomLogo(context, payload) {
    const response = await axios.post(
      `api/parametrosGenerales/save-img-diag/${payload.sameImage}/file`,
      payload.data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response;
  },
  async updateConfigPredet() {
    const response = await axios.get(
      "api/parametrosGenerales/update-config-predet"
    );
    return response;
  },
  async getPermissionsByOptionCode(context, payload) {
    const response = await axios.get(
      `api/Menu/PermissionsByOptionCode/${payload}`
    );
    return response;
  },

  async getOptionsByOptionCode(context, payload) {
    const response = await axios.post(`api/Menu/options?code=${payload}`);
    return response;
  },
  async getMonedas() {
    const response = await axios.get("api/monedas");
    return response.data.data;
  },
  async getTareas() {
    const response = await axios.get("api/TareasProgramadas/GetTareas");
    return response.data.data;
  },
  async updateTareas(context, payload) {
    const response = await axios.post(
      "api/TareasProgramadas/updateTareas",
      payload
    );
    return response;
  },
  async getLogTareasProgramacion(context, payload) {
    const response = await axios.get(
      `api/TareasProgramadas/GetLogTareasProgramacion/${payload}`
    );
    return response.data.data;
  },
  async getMonedasTaxonomy(context, payload) {
    const response = await axios.get(`api/taxonomy/getMonedas/${payload}`);
    return response.data.data;
  },
  async getCotizacionesMonedas() {
    const response = await axios.get("api/monedas/getCotizacionesMonedas");
    return response.data.data;
  },
  async saveMoneda(context, payload) {
    const response = await axios.post("api/monedas/saveMoneda", payload);
    return response;
  },
  async saveCotizacionMoneda(context, payload) {
    const response = await axios.post(
      "api/monedas/saveCotizacionMoneda",
      payload
    );
    return response;
  },
  async deleteCotizacionMoneda(context, payload) {
    const response = await axios.delete(
      `api/monedas/deleteCotizacionMoneda/${payload}`
    );
    return response;
  },
  async deleteMoneda(context, payload) {
    const response = await axios.delete(`api/monedas/deleteMoneda/${payload}`);
    return response;
  },
  async getParamGralMultimoneda() {
    const response = await axios.get("api/monedas/parametroGralMultimoneda");
    return response.data.data;
  },
  async getConfigGeneralDobleFactor() {
    const response = await axios.get(
      "api/dobleFactorAutenticacion/configuracionGeneral"
    );
    return response.data.data;
  },
  async getConfigXUsuarioDobleFactor() {
    const response = await axios.get(
      "api/dobleFactorAutenticacion/configuracionXUsuario"
    );
    return response.data.data;
  },
  async saveConfigGeneralDobleFactor(context, payload) {
    const response = await axios.post(
      "api/dobleFactorAutenticacion/configuracionGeneral",
      payload
    );
    return response;
  },
  async saveConfigXUsuarioDobleFactor(context, payload) {
    const response = await axios.post(
      "api/dobleFactorAutenticacion/configuracionXUsuario",
      payload
    );
    return response;
  },
  async getConfigGral() {
    const response = await axios.get(
      "api/ParametrosGenerales/get-configuracion-general"
    );
    return response.data.data;
  },
  async saveConfigGral(context, payload) {
    const response = await axios.post(
      "api/ParametrosGenerales/save-configuracion-general",
      payload
    );
    return response;
  },
  async getEstadoBackupRealizado() {
    const response = await axios.get(
      "api/ParametrosGenerales/estado-backup-realizado"
    );
    return response.data.data;
  },
  async removeUserQRSecretKey(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/remove-user-qr/${payload}`
    );
    return response.data.data;
  },
  async getGestionUsuarios() {
    const response = await axios.get("api/sysUser/gestionUsuarios");
    return response.data.data;
  },
  async saveUsuario(context, payload) {
    const response = await axios.post("api/sysUser/gestionUsuarios", payload);
    return response;
  },
  async deleteUsuario(context, payload) {
    const response = await axios.delete(
      `api/sysUser/gestionUsuarios/${payload}`
    );
    return response;
  },
  async changeStateUser(context, payload) {
    const response = await axios.post(
      `api/sysUser/gestionUsuarios/cambiarEstado/${payload.id}/${payload.estado}`
    );
    return response;
  },
  async requestTwoFactorAuthenticationUsersAgain() {
    const response = await axios.post(
      "api/sysUser/requestTwoFactorAuthentication"
    );
    return response;
  },
  async restorePassword(context, payload) {
    const response = await axios.post(`api/sysUser/restorePassword/${payload}`);
    return response;
  },
  async getUserMenuOptions(context, payload) {
    const response = await axios.get(`api/sysUser/userMenuOptions/${payload}`);
    return response.data;
  },
  async getPoliticaContraseñas() {
    const response = await axios.get("api/sysUser/politicaContraseñas");
    return response.data.data;
  },
  async postPoliticaContraseñas(context, payload) {
    const response = await axios.post(
      "api/sysUser/politicaContraseñas",
      payload
    );
    return response;
  },
  async getExpConfigReplica() {
    const response = await axios.get(
      "api/ParametrosGenerales/obtener-expedienteweb-configuracion-replica"
    );
    return response.data.data;
  },
  async postExpConfigReplica(context, payload) {
    const response = await axios.post(
      "api/ParametrosGenerales/save-expedienteweb-configuracion-replica",
      payload
    );
    return response;
  },
  async getConfigProceso(context, payload) {
    const response = await axios.post("api/ProcTipoExp/config-proceso", payload);
    return response;
  },
  async getProcTipoExpById(context, payload) {
    const response = await axios.get(`api/ProcTipoExp/${payload}`);
    return response.data.data;
  },
  async deleteProcTipoExp(context, payload) {
    const response = await axios.delete(`api/ProcTipoExp/${payload}`);
    return response;
  },
  async saveProcTipoExp(context, payload) {
    const response = await axios.post("api/ProcTipoExp", payload);
    return response;
  },
  async getSectores(context, payload) {
    const response = await axios.get("api/Sectores", payload);
    return response.data.data;
  },
  async getTipoExp() {
    const response = await axios.get("api/Taxonomy/mTiposExp");
    return response.data.data;
  },
  async getProcesos() {
    const response = await axios.get("api/Taxonomy/mProcedimientos");
    return response.data.data;
  },
  async getProcesoReplica(context, payload) {
    const response = await axios.get(`api/ProcTipoExp/${payload}`);
    return response.data.data;
  },
  async getTipoExpById(context, payload) {
    const response = await axios.get(`api/ProcTipoExp/listar-expediente/${payload}`);
    return response.data.data;
  },
};

function getParents(tree) {
  return tree.filter(parent => parent.parentId === 0);
}

function getChilds(childs) {
  if (childs.length > 0) {
    return childs.map(child => {
      return {
        id: child.optionId,
        name: child.title,
        children: getChilds(child.children),
        type: child.optionType,
        parentId: child.parentId
      };
    });
  } else {
    return [];
  }
}

function mapPermisos(permisos) {
  const { optionsRoles } = permisos;
  const parents = getParents(optionsRoles);
  return parents.map(parent => {
    return {
      id: parent.optionId,
      name: parent.title,
      children: getChilds(parent.children),
      type: parent.optionType,
      parentId: 0
    };
  });
}
function selected(permisos) {
  const { optionsRoles } = permisos;
  let selectedOptions = [];
  return getSelectedOption(optionsRoles, selectedOptions);
}
function getSelectedOption(optionsRoles, selectedOptions) {
  optionsRoles.forEach(element => {
    if (element.selected) selectedOptions.push(element.optionId);
    if (element.children && element.children.length > 0) {
      getSelectedOption(element.children, selectedOptions);
    }
  });

  return selectedOptions;
}

export default actions;
