import axios from "@/axios/axios-api";
const actions = {
  async getConfiguracionesConceptos() {
    const response = await axios.get(
      "api/procesosDesregulacion/configuracionesConceptos"
    );
    return response.data.data;
  },
  async getProcesosLiquidacion() {
    const response = await axios.get("api/taxonomy/fetchProcesosLiquidacion");
    return response.data;
  },
  async saveConfiguracionConcepto(context, payload) {
    const response = await axios.post(
      "api/procesosDesregulacion/guardarConfiguracionConcepto",
      payload
    );
    return response;
  },
  async deleteConfigConcepto(context, payload) {
    const response = await axios.delete(
      `api/procesosDesregulacion/eliminarConfiguracionConcepto/${payload}`
    );
    return response;
  },
  async getComisionesConceptos() {
    const response = await axios.get(
      "api/procesosDesregulacion/comisionesDeConceptos"
    );
    return response.data.data;
  },
  async getConceptosDesregulacion() {
    const response = await axios.get("api/taxonomy/conceptosDesregulacion");
    return response.data;
  },
  async saveComisionConcepto(context, payload) {
    const response = await axios.post(
      "api/procesosDesregulacion/guardarComisionDeConcepto",
      payload
    );
    return response;
  },
  async deleteComisionConcepto(context, payload) {
    const response = await axios.delete(
      `api/procesosDesregulacion/eliminarComisionDeConcepto/${payload}`
    );
    return response;
  },
  async fetchConveniosByFilters(context, payload) {
    const response = await axios.get(`api/procesosDesregulacion/${payload}`);

    return response.data.data;
  },

  async fetchConsultaProcesos(context, payload) {
    const response = await axios.post(
      "api/procesosDesregulacion/ConsultaProcesos",

      payload
    );

    return response.data.data;
  },

  async getProcesosDeta(context, payload) {
    const response = await axios.get(
      `api/ProcesosDesregulacion/ProcesosDeta/${payload}`
    );

    return response.data.data;
  },

  async calcularComisionNuevoProcesoDeta(context, payload) {
    const response = await axios.post(
      "api/ProcesosDesregulacion/CalcularComision",

      payload
    );

    return response.data.data;
  },
  async quitarConceptoProcesoDeta(context, payload) {
    const response = await axios.delete(`api/ProcesosDesregulacion/QuitarProcesoDeta/${payload.liqDesConcId}`);
    return response;
  },

  async agregarConceptoProcesoDeta(context, payload) {
    const response = await axios.post(
      "api/ProcesosDesregulacion/NuevoProcesoDeta",payload
    );

    return response;
  },

  async fetchEstadosProcesosDesregulacion() {
    const response = await axios.get(
      "api/taxonomy/estadosProcesosDesregulacion"
    );

    return response.data;
  },

  async getConceptosNuevoConcepto(context, payload) {
    const response = await axios.get(
      `api/ProcesosDesregulacion/ConceptosByProceso/${payload}`
    );

    return response.data.data;
  },

  async cambiaEstadoProcesoDesregulacion(context, payload) {
    const response = await axios.post(
      "api/procesosDesregulacion/EstadoProcesoDesregulacion",

      payload
    );

    return response;
  },

  async getDetalleConceptoAutomatico(context, payload) {
    const response = await axios.get(
      `api/procesosDesregulacion/DetalleConceptoAutomatico/${payload}`,
    );

    return response;
  },
  async nuevoProcesoDesregulacion(context, payload) {
    const response = await axios.post(
      "api/ProcesosDesregulacion/ProcesarLiquidacionDesregulacion", payload
    );

    return response;
  },
  async reprocesarConceptoDesregulacion(context, payload) {
    const response = await axios.post(
      "api/ProcesosDesregulacion/ReprocesaConceptoAuto",payload
    );

    return response;
  },
 
  async anulaProcesoDesregulacion(context, payload) {
    const response = await axios.delete(`api/ProcesosDesregulacion/AnularProcesoDesregulacion/${payload.liqDesProcId}`);
    return response;
  },
  
};
export default actions;
