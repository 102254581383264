const getAPIUrl = async () => {
    //si el environtment es development devuelvo hardcodeada la url de la API
    if (process.env.NODE_ENV == 'development') return `${process.env.VUE_APP_URL}`;
    //con axios me da out of memory...
    const request = await fetch('/plugins/static/conn.json');
    const response = await request.json();    
    //si no siempre retorna la url basada en el protocolo y puerto del archivo de configuración
    if(response.url) return response.url;
    else return `${response.protocol}://${window.location.hostname}:${response.port}/`;    
};

export default getAPIUrl;