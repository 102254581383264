<template>
  <button 
    role="button" 
    :disabled="disabled"
    class="custom-btn" 
    :class="customStyles" 
    @click=" (!disabled)? $emit('click') : false"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    outlined: Boolean,
    disabled: Boolean,
    flat: Boolean,
    small: Boolean,
    large: Boolean,
    block: Boolean,
    rounded: Boolean,
    bordernone: Boolean
  },
  data() { 
    return {};
  },
  computed: {
    customStyles() {
      return {
        outlined: this.outlined,
        flat: this.flat,
        small: this.small,
        large: this.large,
        block: this.block,
        rounded: this.rounded,
        disabled: this.disabled,
        bordernone: this.bordernone
      };
    }
  }
};
</script>
<style lang="scss" scoped>
  .custom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 4px 20px;
    border-radius: 4px;
    user-select: none;
    background: #195472;
    color: white;
    transition: background-color .1s linear;
    cursor: pointer;
    
    &.disabled {
      background: #cfcfcf;
      color:#7e7e7e;
    }

    &:hover {
      background: var(--green-color-dark);
      &.disabled {
        background: #cfcfcf;
      }
    }
    &:active {
      background: var(--green-color-light);
      &.disabled {
        background: #cfcfcf;
      }
    }
    &:focus {
      box-shadow: 0 0 5px 2px var(--green-color-shadow);
      &.disabled {
        box-shadow: none;
      }
    }
    &.outlined {
      background: transparent;
      border: 1px solid #195472;
      color:#195472;

      &.disabled {
        border-color: #7e7e7e;
        color:#7e7e7e;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.396);
        &.disabled {
          background: transparent;
        }
      }
    }
    &.flat {
      background: transparent;
      color: #195472;
      &.disabled {
        color: #7e7e7e;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.07);
        &.disabled {
          background: transparent;
        }
      }
      &:active, &:focus {
        background: var(--green-color-shadow);
        box-shadow: none;
        &.disabled {
          background: transparent;
        }
      }
    }
    &.rounded {
      border-radius: 50px !important;
    }
    &.small {
      padding: 5px 13px;
    }
    &.large {
      padding: 13px 25px;
    }
    &.block {
      width: 100% !important;
    }
    &.bordernone {
      border:none !important;
    }
  }
</style>
