<template>
  <div class="footer">
    <p>GECROS un producto de <img src="@/assets/img/macena_gray.svg" alt="Macena"></p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style>
.footer {
  padding: 36px;
  text-align: center;
  font-size: 14px;
  }
</style>