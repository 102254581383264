<template>
  <div>
    <v-form
      form="change-password-form"
      ref="change-password-form"
      id="change-password-form"
      v-model="valid"
      @submit.prevent="saveNewPassword()"
    >
      <v-row justify="center">
        <v-dialog v-model="modalOpen" persistent max-width="450px">
          <v-card>
            <v-card-title>
              <span class="primary--text"
                >Cambiar contraseña - {{ userLogged.nombre }}</span
              >
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="currentPassword"
                    :append-icon="
                      !currentPassword
                        ? ''
                        : currentPassTextType
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (currentPassTextType = !currentPassTextType)
                    "
                    :type="currentPassTextType ? 'password' : 'text'"
                    label="Contraseña actual"
                    autocomplete="text-currentPassword"
                    outlined
                    dense
                    :rules="
                      currentPassRules.concat(
                        rules.maxLength(currentPassword, 25)
                      )
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    :append-icon="
                      !newPassword
                        ? ''
                        : newPassTextType
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    @click:append="() => (newPassTextType = !newPassTextType)"
                    :type="newPassTextType ? 'password' : 'text'"
                    label="Nueva contraseña"
                    hint="Escriba una contraseña"
                    outlined
                    autocomplete="text-newPassword"
                    dense
                    v-model="newPassword"
                    :rules="
                      newPassRules.concat(
                        validateNewPass(newPassword),
                        rules.maxLength(newPassword, 25)
                      )
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    :append-icon="
                      !confirmPassword
                        ? ''
                        : repeatNewPassTextType
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (repeatNewPassTextType = !repeatNewPassTextType)
                    "
                    :type="repeatNewPassTextType ? 'password' : 'text'"
                    label="Confirmar contraseña"
                    hint="Repita su nueva contraseña"
                    autocomplete="text-confirmPassword"
                    outlined
                    dense
                    v-model="confirmPassword"
                    :rules="[
                      newPassword === confirmPassword ||
                        'Las contraseñas no coinciden'
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="withoutCancel"
                color="blue darken-1"
                text
                @click="closeSession()"
              >
                Cerrar sesión
              </v-btn>
              <v-btn
                v-if="!withoutCancel"
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                type="submit"
                form="change-password-form"
                :disabled="!valid"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "@/axios/axios-api";
import rules from "@/utils/helpers/rules";

export default {
  name: "ChangePassword",
  props: {
    withoutCancel: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      valid: false,
      modalOpen: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      currentPassRules: [v => !!v || "Ingrese su contraseña"],
      newPassRules: [v => !!v || "Ingrese nueva contraseña"],
      polSec: null,
      currentPassTextType: String,
      newPassTextType: String,
      repeatNewPassTextType: String,
      rules: rules
    };
  },
  created() {
    this.$bus.$on("openModal", data => {
      this.modalOpen = data;
    });
  },
  watch: {
    modalOpen(val) {
      if (val) this.getPoliticaContraseñaFunction();
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      userLogged: "user/user"
    })
  },
  methods: {
    ...mapActions({
      getPoliticaContraseñas: "AdministracionSistema/getPoliticaContraseñas",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$refs["change-password-form"].reset();
      this.$refs["change-password-form"].resetValidation();
      this.modalOpen = false;
      this.$emit("close", false);
    },
    async getPoliticaContraseñaFunction() {
      this.polSec = await this.getPoliticaContraseñas();
    },
    validateNewPass(pass) {
      if (!pass) {
        return [true];
      }

      if (/\s/.test(pass)) return ["La contraseña no puede contener espacios."];

      let longMin = this.polSec.longitudMinima;
      if (pass.length < longMin) {
        return [`Longitud mínima: ${longMin} caracteres`];
      }
      if (this.polSec.exigeLetrasNumeros) {
        if (!/[a-zA-Z]/.test(pass) || !/\d/.test(pass)) {
          return ["La contraseña debe contener letras y números."];
        }
      }

      return [true];
    },
    saveNewPassword() {
      if (this.$refs["change-password-form"].validate()) {
        axios
          .post(
            `api/SysUser/changepass?currentPass=${this.currentPassword}&newPass=${this.newPassword}`
          )
          .then(passwordChanged => {
            if (passwordChanged) {
              this.$swal.fire({
                icon: "success",
                title: "Su contraseña ha sido guardada",
                showConfirmButton: false,
                timer: 3000
              });
              this.closeModal();
            }
          })
          .catch(e => {
            this.$swal.fire({
              icon: "error",
              title: e.response.data.error[0],
              showConfirmButton: false,
              timer: 3000
            });
          });
      }
    },
    closeSession() {
      this.$store.dispatch("user/setLogoutMvc", () => {
        localStorage.removeItem("token");
        this.$router.push("/login");
      });
    }
  }
};
</script>
