import icons from './icons';
import messages from './messages';
import modules from './modules/index';
import titles from './titles';
import buttons from './buttons';
import frameworks from './frameworks';
import webSiteOptions from './webSiteOptions';
export default {
  icons,
  messages,
  modules,
  titles,
  buttons,
  frameworks,
  webSiteOptions
};