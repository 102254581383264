let vm;

exports.install = (Vue) => {
    if (!vm) {
        vm = new Vue({
            data() {
                return {};
            },
            mounted() {
                this.$emit('initBus', 'initiated');
            }
        });
        Vue.prototype.$bus = vm;
    }
};