import axios from "@/axios/axios-api";
const actions = {
  async getAgentesCuenta() {
    const response = await axios.get("api/agentesCuenta");
    return response.data.data;
  },
  async saveAgenteCuenta(context, payload) {
    const response = await axios.post("api/agentesCuenta", payload);
    return response;
  },
  async deleteAgenteCuenta(context, payload) {
    const response = await axios.delete(
      `api/agentesCuenta/gestionAgente/${payload}`
    );
    return response;
  },
  async fetchAgentesByParams(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/gestionAgentesCuenta/",
      payload
    );
    return response.data.data;
  },
  async getFormasPagoAgente(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/formasPagoAgente/${payload}`
    );
    return response.data.data;
  },
  async getFormaPagoAgenteById(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/formaPagoAgenteById/${payload}`
    );
    return response.data.data;
  },
  async saveFormaPagoAgente(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/formaPagoAgente/",
      payload
    );
    return response;
  },
  async deleteFormaPagoAgente(context, payload) {
    const response = await axios.delete(
      `api/agentesCuenta/formaPagoAgente/${payload}`
    );
    return response;
  },
  async getTiposAlias() {
    const response = await axios.get("api/taxonomy/tiposAlias");
    return response.data.data;
  },
  async getAliasAgente(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/agentesCtaAlias/${payload}`
    );
    return response.data.data;
  },
  async saveAliasAgente(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/agentesCtaAlias/",
      payload
    );
    return response;
  },
  async deleteAliasAgente(context, payload) {
    const response = await axios.delete(
      `api/agentesCuenta/agentesCtaAlias/${payload}`
    );
    return response;
  },
  async getDatosAdicionalesAgenteBenef(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/datosAdicionalesAgenteBenef/${payload.id}/${payload.benGrId}/${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async getDatosAdicionalesAgenteCuenta(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/datosAdicionalesAgenteCuenta/${payload.id}/${payload.fechaConsulta}`
    );
    return response.data.data;
  },
  async getRelacionesGrupoFamiliar(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/relacionGrupoAgenteCuenta/${payload}`
    );
    return response.data.data;
  },
  async getRelacionGrupoFamiliarById(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/relacionGrupoAgenteCuentaById/${payload}`
    );
    return response.data.data;
  },
  async getTipoRelacion() {
    const response = await axios.get("api/taxonomy/tipoBenAge");
    return response.data.data;
  },
  async saveRelacionGrupoFamiliar(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/saveRelacionGrupoAgenteCuenta",
      payload
    );
    return response;
  },
  async fetchAfiliadosByParams(context, payload) {
    const response = await axios.post(
      "api/afiliados/informacionAfiliados",
      payload
    );
    return response.data.data;
  },
  async deleteRelacionGrupoFamiliar(context, payload) {
    const response = await axios.delete(
      `api/agentesCuenta/deleteRelacionGrupoAgenteCuenta/${payload}`
    );
    return response;
  },
  async getComprobantesCancelatorios(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/comprobantesCancelatorios",
      payload
    );
    return response.data.data;
  },
  async getComprobantesCancelatoriosMultimoneda(context, payload) {
    const response = await axios.post(
      "api/agentesCuenta/comprobantesCancelatoriosMultimoneda",
      payload
    );
    return response.data.data;
  },
  async getAlicuotasPercibidasAgenteCuenta(context, payload) {
    const response = await axios.get(
      `api/agentesCuenta/alicuotasPercibidas/${payload.agectaId}/${payload.percepcion}/${payload.juribId}`,
      payload
    );
    return response.data.data;
  },
  async getJurisdiccionesIIBB() {
    const response = await axios.get(
      "api/taxonomy/jurisdiccionesIIBBByEmpresa"
    );
    return response.data.data;
  }
};
export default actions;
