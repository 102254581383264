const mutations = {
  setFiltersTop(state, filters) {
    state.filtersTop = filters;
  },
  setInfoTable(state, infoTable) {
    state.infoTable = infoTable;
  },
  setConfiguracionPrestadoresFiltersTop(state, filters) {
    state.configuracionPrestadoresFiltersTop = filters;
  },
  setConfiguracionPrestadoresInfoTable(state, infoTable) {
    state.configuracionPrestadoresInfoTable = infoTable;
  },

  setListCheck(state, data) {
    state.ListCheck = data;
  },
  ///imputSelectChech
  setNivelTodoSel(state, data) {
    state.TodoSel = data;
  },
  setNivelSeleccionados(state, data) {
    state.TodoSel = data;
  },
  setDataRow(state, data) {
    state.dataRow = data;
  },
  setPrestadoresByQuery(state, prestadores) {
    state.prestadoresByQuery = prestadores;
  },
  setOrigenesByQuery(state, origenes) {
    state.origenesByQuery = origenes;
  },
};

export default mutations;