import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Spanish from 'vuetify/es5/locale/es'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#195472',
                secondary: '#E0F2F1',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FF9800',
            },
        },
    },
    iconfont: 'mdi',
    lang: {
        locales: { Spanish },
        current: 'Spanish',
    },
});