<template>
  <div class="backdrop" :class="{opaque: opaque}">
    
    <div class="card-loader">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <!-- <img src="@/assets/img/loader.svg" width="50px" alt=""> -->
      <span v-if="text">{{text}}</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    text: {
      type: String,
      default: ''
    },
    opaque: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  .backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 120vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.4);

    &.opaque {
      background: rgb(255, 255, 255);
    }

    .card-loader {
      background: white;
      border-radius: 20px;
      margin-top: -20vh;
      padding: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }
</style>