import axios from "@/axios/axios-api";

const actions = {
  async getParametrosGralesTopes() {
    const response = await axios.get(
      `api/TopesGenerales/GetParametrosGralesTopes`
    );
    return response.data.data;
  },
  async getAllTopesToExport() {
    const response = await axios.get(`api/TopesGenerales/getAllTopesGrales`);
    return response.data.data;
  },
  async getTopesGenerales(context, payload) {
    const response = await axios.post(
      `api/TopesGenerales/getTopesGenerales`,
      payload
    );
    return response.data.data;
  },
  async saveTopeGrupoPractica(context, payload) {
    try {
      const response = await axios.post(
        `api/TopesGenerales/saveTopesGenerales`,
        payload
      );
      return response;
    } catch (error) {
      return error.response.data.errors[0];
    }
  },
  async deleteTopesGenerales(context, payload) {
    const response = await axios.delete(
      `api/TopesGenerales/deleteTopesGenerales/${payload}`
    );
    return response;
  },
  async saveProcesoImportacionTopes(context, payload) {
    const response = await axios.post(
      `api/TopesGenerales/saveTopesGeneralesImportacion`,
      payload
    );
    return response;
  },
  async getProcesosImportacionTopesGenerales() {
    const response = await axios.get(
      `api/TopesGenerales/GetProcesosImportacionTopes`
    );
    return response.data.data;
  },
  async getFormatoProcesoImportacionTopes() {
    const response = await axios.get(
      `api/TopesGenerales/getFormatoProcesoImportacionTopes`
    );
    return response.data.data;
  },
  async anularProcesoImportacionTopesGenerales(context, payload) {
    const response = await axios.get(
      `api/TopesGenerales/anularProcesoImportacion/${payload}`
    );
    return response;
  },
  async getCodigosSinConfigurarTopes(context, payload) {
    const response = await axios.get(
      `api/TopesGenerales/codigosSinConfigurar/${payload}`
    );
    return response.data.data;
  }
};
export default actions;
