const state = {
  filtersTop: [],
  headerTable: null,
  infoTable: null,
  dataRow: null,
  nuevasActasInfoTable: null,
  nuevasActasFiltersTop: null,
  afiliadosByQuery: [],
  empresasByQuery: [],
};

export default state;