import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async getNuevasActasFiltersTop(context) {
    const conveniosItems = await axios.get("api/obrassociales");
    const estadosItems = await axios.get("api/estadosactas/iniciales");
    const entidadesItems = await axios.get("api/entidadesFacturantes");
    let moduleLoaded = localStorage.getItem("moduleLoaded");
    moduleLoaded = JSON.parse(moduleLoaded);

    const convenios = {
      model: null,
      items: conveniosItems.data.data
    };

    const estados = {
      model: null,
      items: estadosItems.data.data
    };

    const entidades = {
      model: null,
      items: entidadesItems.data.data
    };
    const filtersTop = { convenios, estados, entidades };

    context.commit("setNuevasActasFiltersTop", filtersTop);
  },

  async getNuevasActasInfoTable(context, payload) {
    const result = await axios.post(
      "api/procesosfiscalizacion/aportes",
      payload.bodyParams
    );
    return result;
  },

  async getSentEmails() {
    const response = await axios.get("api/actasFiscalizacion/SentEmails");
    return response.data.data;
  },

  async getSentEmailByActaId(context, payload) {
    const response = await axios.get(
      `api/actasFiscalizacion/SentEmail/${payload.actaId}`
    );
    return response.data.data;
  },

  async fetchAfiliadosByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const afiliados = await axios.get(
        `api/afiliados/autocomplete?nombreAfiliado=${payload.input}`
      );
      return afiliados.data.data;
    }
  },

  async fetchEmpresasByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const empresas = await axios.get(
        `api/empresas/autocomplete?input=${payload.input}`
      );
      return empresas.data.data;
    }
  },
  async fetchConvenios(context) {
    const convenios = await axios.get("api/obrassociales");
    return convenios.data.data;
  },

  async fetchEstadosActaDestino(context, ultimoEstadoId) {
    const estadosActa = await axios.get(
      `api/estadosActas/origenDestino/${ultimoEstadoId}`
    );
    return estadosActa.data.data;
  },

  async fetchProcesosFiscalizacion(context, payload) {
    const result = await axios.post(
      "api/procesosFiscalizacion/getByFilters",
      payload.params
    );
    return result.data.data;
  },

  async fetchActasFiscalizacion(context, payload) {
    const result = await axios.post("api/actasFiscalizacion", payload.params);
    return result.data.data;
  },

  async getEmpresaByActaId(context, actaId) {
    let response = null;
    if (actaId != null) {
      response = await axios.get(
        `api/actasFiscalizacion/empresaByActaId/${actaId}`
      );
    } else {
      response = await axios.get("api/actasFiscalizacion/empresaByActaId");
    }
    return response.data.data;
  },

  async getPeriodosByEmpresaId(context, payload) {
    payload.empresaId = 1;
    const response = await axios.get(
      `api/ActasFiscalizacion/periodosByActaId?actaId=${payload.empresaId}`
    );
    return response.data;
  },

  async getDetallePeriodo(context, payload) {
    const response = await axios.get(
      `api/ActasFiscalizacion/detallePeriodo?actaId=${payload.bodyParams.actaId}&periodo=${payload.bodyParams.periodo}`
    );
    return response.data;
  },

  async fetchActaFiscalizacionEstados(context, payload) {
    const actaId = payload.actaId;
    const response = await axios.get(
      `api/actasFiscalizacion/actaEstado/${actaId}`
    );
    return response.data.data;
  },

  async getEmpresaById(context, payload) {
    const response = await axios.get(
      `api/empresas/GetEmpresaById?empresaId=${payload.empresaSelected}`
    );
    return response.data;
  },

  async getActasByProcesoId(context, payload) {
    const response = await axios.get(
      `api/actasFiscalizacion/actasByProcesoId?procId=${payload.procesoId}`
    );
    return response.data;
  },

  async getProcesoFiscalizacionById(context, payload) {
    const response = await axios.get(
      `api/procesosFiscalizacion/${payload.procesoId}`
    );
    return response.data.data;
  },

  async fetchInspectores() {
    const response = await axios.get("api/proveedores/inspectores");
    return response.data.data;
  },

  async updateActaFiscalizacion(context, payload) {
    const response = await axios.post("api/actasFiscalizacion/acta", payload);
    return response;
  },

  async updateEmailActaFiscalizacion(context, payload) {
    const response = await axios.put(
      "api/actasFiscalizacion/EmailActa",
      payload
    );
    return response;
  },

  async updateInspectorActasFiscalizacion(context, payload) {
    const response = await axios.put(
      "api/actasFiscalizacion/inspectorActa",
      payload
    );
    return response;
  },

  async updateActaFiscalizacionEstado(context, payload) {
    const response = await axios.post(
      "api/actasFiscalizacion/actaEstado",
      payload
    );
    return response;
  },

  async getPeriodosByActaId(context, payload) {
    const response = await axios.get(
      `api/ActasFiscalizacion/periodosByActaId?actaId=${payload.actaId}`
    );
    return response.data;
  },

  async grabarProcesoFiscalizacion(context, payload) {
    const proceso = await axios.post(
      `api/procesosFiscalizacion/${payload.proceso}`,
      payload.bodyParams
    );
    return proceso.data;
  },

  async deleteEmpleadoByActaIdBenId(context, payload) {
    const response = await axios.delete(
      `api/actasfiscalizacion/afiliado?detallePeriodoId=${payload.detallePeriodoId}`
    );
    return response.data;
  },

  async deleteActaById(context, payload) {
    const response = await axios.delete(
      `api/actasfiscalizacion?actaId=${payload.actaId}`
    );
    return response;
  },

  async deleteProcesoFiscalizacion(context, payload) {
    const response = await axios.delete(
      `api/procesosfiscalizacion/${payload.procesoId}`
    );
    return response;
  },

  async confirmarGrabado(context, payload) {
    const response = await axios.post(
      "api/procesosfiscalizacion/confirmar",
      payload.bodyParams
    );
    return response;
  },

  async deletePeriodoOfActa(context, payload) {
    const response = await axios.delete(
      `api/actasfiscalizacion/periodo?actaId=${payload.bodyParams.actaId}&periodo=${payload.bodyParams.periodo}`
    );
    return response.data.data;
  },

  async fetchActasFiscalizacionAfip(context, payload) {
    const result = await axios.post(
      "api/actasFiscalizacion/actasAFIP",
      payload.params
    );
    return result.data.data;
  },
  async getConceptosManualesByActaId(context, payload) {
    const response = await axios.get(
      `api/actasFiscalizacion/ConceptosActasFiscalizacion/${payload}`
    );
    return response.data.data;
  },
  async saveConceptoActasFiscalizacion(context, payload) {
    const response = await axios.post(
      "api/actasFiscalizacion/ConceptosActasFiscalizacion",
      payload
    );
    return response;
  },
  async interesByCuit(context, payload) {
    const response = await axios.post(
      "api/actasFiscalizacion/GetInteresCuit",
      payload
    );
    return response.data.data;
  },
  async deleteConceptoActasFiscalizacion(context, payload) {
    const response = await axios.delete(
      `api/actasFiscalizacion/ConceptosActasFiscalizacion/${payload}`
    );
    return response;
  },
  async getActasARelacionarProceso(context, payload) {
    const response = await axios.get(
      `api/actasFiscalizacion/ActasSinDdjj/${payload}`
    );
    return response.data.data;
  },
  async saveRelacionActaSinProcesoDDJJ(context, payload) {
    const response = await axios.post(
      "api/actasFiscalizacion/AsociaActasFiscalizacionProceso",
      payload
    );
    return response;
  },
  async confirmarAsociaProcesoActaExistente(context, payload) {
    const response = await axios.post(
      `api/procesosFiscalizacion/confirmarProcesoActaExistente/${payload.procesoFiscalizacionId}`,
      payload
    );
    return response;
  },

  async generarArchivoInformeAfip(context, payload) {
    const result = await axios.post(
      "api/actasFiscalizacion/archivoAfip",
      payload.params
    );
    return result;
  },

  async fetchArchivosFiscalizacionAfip(context, payload) {
    const result = await axios.post(
      "api/actasFiscalizacion/archivosAfip",
      payload.params
    );
    return result.data.data;
  },

  async getArchivoFiscalizacionById(context, archivoId) {
    const response = await axios.get(
      `api/actasFiscalizacion/archivoAfip/${archivoId}`
    );
    return response.data.data;
  },

  async getActaFiscalizacionById(context, actaId) {
    const response = await axios.get(`api/actasFiscalizacion/${actaId}`);
    return response.data.data;
  },

  async getArchivoFiscalizacionDetalleById(context, archivoId) {
    const response = await axios.get(
      `api/actasFiscalizacion/archivoAfip/detalle/${archivoId}`
    );
    return response.data.data;
  },

  async getArchivosByActaId(context, actaId) {
    const response = await axios.get(
      `api/actasFiscalizacion/archivoByActaId?id=${actaId}`
    );
    return response.data.data;
  },

  async sendEmail(context, payload) {
    const response = await axios.post(
      "api/actasFiscalizacion/SendEmail",
      payload
    );
    return response;
  },

  async generarArchivoActasPdf(context, payload) {
    const token = localStorage.getItem("token");
    const urlAPI = await getAPIUrl();
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      if (process.env.NODE_ENV == "development") {
        xhr.open("POST", `${urlAPI}/api/actasFiscalizacion/exportPDF`, true);
      } else {
        xhr.open(
          "POST",
          `${
            urlAPI.endsWith("/") ? urlAPI.slice(0, -1) : urlAPI
          }/api/actasFiscalizacion/exportPDF`,
          true
        );
      }
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8");
      xhr.responseType = "arraybuffer";

      let empName = payload.data.EmpresaName;
      empName = empName.replaceAll(".", " ");
      let actaId = payload.data.ActaFiscalizacionId;
      let params = [...payload.data.ActaFiscalizacionId];
      params.push(payload.data.PdfTemplateId);

      xhr.send(JSON.stringify(params));

      xhr.onload = e => {
        if (e.target.status === 200) {
          resolve(e.target.status);
          const fileName = `Acta ${actaId} - ${empName}`;
          let blob = new Blob([e.target.response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", fileName);
          link.download = fileName;
          link.click();
        } else {
          context.commit(
            "user/setAlertMessage",
            {
              type: "warning",
              message: "No se pudo recuperar el archivo"
            },
            { root: true }
          );
          reject(e.target.status);
        }
      };
    });
  },

  async deleteProcesoAfip(context, payload) {
    const response = await axios.delete(
      `api/actasfiscalizacion/procesoFiscalizacionAfip?procesoAfipId=${payload}`
    );
    return response;
  },

  async deleteEstadoActaFiscalizacion(context, payload) {
    const response = await axios.delete(
      `api/actasfiscalizacion/estadoactafiscalizacion?estadoActaId=${payload}`
    );
    return response;
  },
  async getEstadosActasIniciales(context, payload) {
    const response = await axios.get("api/estadosActas/iniciales");
    return response.data.data;
  },

  async fetchEntidadesFiscalizadoras() {
    const response = await axios.get("api/entidadesFacturantes");
    return response.data.data;
  },
  async getDateTime() {
    const response = await axios.get("api/status/DateTime");
    return response.data.data;
  },
  async getDDJJVsAportesByParams(context, payload) {
    const response = await axios.post("api/ddjjAportes/dDJJVsAportes", payload);
    return response.data.data;
  },
  async getDDJJByParams(context, payload) {
    const response = await axios.post("api/ddjjAportes/dDJJ", payload);
    return response.data.data;
  },
  async getAportesByParams(context, payload) {
    const response = await axios.post("api/ddjjAportes/aportes", payload);
    return response.data.data;
  },
  async postAportesModificados(context, payload) {
    const response = await axios.post(
      "api/ddjjAportes/updateConvenioAportes",
      payload
    );
    return response;
  },
  async getDescuentosAportesContribuciones() {
    const response = await axios.get("api/descuentosAportesContribuciones");
    return response;
  },
  async getTiposDescuentosAportesContribuciones() {
    const response = await axios.get(
      "api/taxonomy/tiposDescuentosAportesContribuciones"
    );
    return response.data.data;
  },
  async getDescuentoAportesContribucionesById(context, payload) {
    const response = await axios.get(
      `api/descuentosAportesContribuciones/descuentoAportesContribucionesById/${payload}`
    );
    return response.data.data;
  },
  async postDescuentoAportesContribuciones(context, payload) {
    const response = await axios.post(
      "api/descuentosAportesContribuciones",
      payload
    );
    return response;
  },
  async deleteDescuentoAportesContribuciones(context, payload) {
    const response = await axios.delete(
      `api/descuentosAportesContribuciones/${payload}`
    );
    return response;
  },
  async getConceptosAFIP() {
    const response = await axios.get("api/conceptosAFIP");
    return response;
  },
  async getTiposConceptosAFIP() {
    const response = await axios.get("api/conceptosAFIP/tiposConceptosAFIP");
    return response.data.data;
  },
  async postConceptoAFIP(context, payload) {
    const response = await axios.post("api/conceptosAFIP", payload);
    return response;
  },
  async deleteDescuentoAFIP(context, payload) {
    const response = await axios.delete(`api/conceptosAFIP/${payload}`);
    return response;
  },

  async getTiposComprobantesCobro() {
    const response = await axios.get(
      "api/DDJJAportes/tipos-comprobantes-cobro-aportes"
    );
    return response;
  },
  // async getTiposConceptosAFIP() {
  //   const response = await axios.get("api/conceptosAFIP/tiposConceptosAFIP");
  //   return response.data.data;
  // },
  async postTipoComprobanteCobro(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/tipos-comprobantes-cobro-aportes",
      payload
    );
    return response;
  },

  async getTablasRemuneracionesMinimas() {
    const response = await axios.get(
      "api/remuneracionesMinimas/tablasRemuneracionesMinimas"
    );
    return response;
  },
  async postTablasRemuneracionesMinimas(context, payload) {
    const response = await axios.post(
      "api/remuneracionesMinimas/saveTablasRemuneracionesMinimas",
      payload
    );
    return response;
  },
  async deleteTablasRemuneracionesMinimas(context, payload) {
    const response = await axios.delete(
      `api/remuneracionesMinimas/tablasRemuneracionesMinimas/${payload}`
    );
    return response;
  },
  async getAllRemuneracionesMinimasById(context, payload) {
    const response = await axios.get(
      `api/remuneracionesMinimas/remuneracionesMinimasById/${payload}`
    );
    return response;
  },
  async getOneRemuneracionMinimaById(context, payload) {
    const response = await axios.get(
      `api/remuneracionesMinimas/remuneracionMinimaById/${payload}`
    );
    return response.data.data;
  },
  async postRemuneracionesMinimasById(context, payload) {
    const response = await axios.post(
      "api/remuneracionesMinimas/saveRemuneracionesMinimasById",
      payload
    );
    return response;
  },
  async deleteRemuneracionesMinimasById(context, payload) {
    const response = await axios.delete(
      `api/remuneracionesMinimas/remuneracionesMinimasById/${payload}`
    );
    return response;
  },
  async getRemuneracionesMinimasEmpresasById(context, payload) {
    const response = await axios.get(
      `api/remuneracionesMinimas/remuneracionesMinimasEmpresasById/${payload}`
    );
    return response.data.data;
  },
  async postRemuneracionesMinimasEmpresasById(context, payload) {
    const response = await axios.post(
      "api/remuneracionesMinimas/saveremuneracionesMinimasEmpresasById",
      payload
    );
    return response;
  },
  async deleteRemuneracionesMinimasEmpresasById(context, payload) {
    const response = await axios.delete(
      `api/remuneracionesMinimas/remuneracionesMinimasEmpresasById/${payload}`
    );
    return response;
  },

  // Tablas Sicoss
  async getTablasSicossByFilters(context, payload) {
    const response = await axios.post(
      "api/TablasSicoss/tablas-sicoss-by-filters",
      payload
    );
    return response.data.data;
  },
  async saveTablaSicoss(context, payload) {
    const response = await axios.post(
      "api/TablasSicoss/save-tabla-sicoss",
      payload
    );
    return response;
  },
  async deleteTablaSicoss(context, payload) {
    const response = await axios.delete(
      `api/TablasSicoss/delete-tabla-sicoss/${payload.sicossConfigId}`
    );
    return response;
  },
  async getTablasSicossSelect() {
    const response = await axios.get("api/taxonomy/tablasSicossItems");
    return response.data.data;
  },
  async fetchSituacionesRevistas() {
    const response = await axios.get("api/taxonomy/situacionesRevista");
    return response.data.data;
  },
  async fetchProcesosDistribucion(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/ProcesosDistribucionAportes",
      payload
    );
    return response.data.data;
  },
  async anularProcesoDistribucion(context, payload) {
    const response = await axios.post(
      `api/DDJJAportes/AnularProcesoDistribucionAportes/${payload}`
    );
    return response;
  },
  async agregarAportesContribucionesADistribuir(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/agregarAportesContribucionesADistribuir",
      payload
    );
    return response;
  },
  async procesarProcesoDistribucionAportes(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/procesarProcesoDistribucionAportes",
      payload
    );
    return response;
  },
  async saveProcesoDistribucionAporte(context, payload) {
    const response = await axios.post(
      "api/DDJJAportes/grabarProcesoDistribucionAportes",
      payload
    );
    return response;
  },
  async recalcularInteresActaFiscalizacion(context, payload) {
    const response = await axios.post(
      "api/ActasFiscalizacion/RecalcularInteres",
      payload
    );
    return response;
  },
  async getRecalculosInteresActaFiscalizacion(context,payload) {
    const response = await axios.get(`api/ActasFiscalizacion/RecalculoInteres/${payload}`);
    return response.data.data;
  },
  async deleteRecalculoInteres(context, payload) {
    const response = await axios.delete(`api/ActasFiscalizacion/DeleteRecalculoInteres?ids=${payload.join(',')}`);
    return response;
  },
};

export default actions;
