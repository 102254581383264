//import { mapGetters } from 'vuex';
import axios from '@/axios/axios-api';
import getPayload from '@/utils/helpers/getPayload';
const actions =
{ 
async guardar(contex,params) {
    
    let parametro = params.bodyParams[0].value; //getPayload(params.bodyParams.mapFilters);
    let wrdo=await axios.post('api/menu/filters/save', parametro);
    contex.commit('setseleccionados', parametro);
    return wrdo;
},
async masutilizados(contex,params){
    let parametro = getPayload(params.bodyParams);
    await axios.get(`/api/menu/mostusedfilters/${parametro.id}`);
    contex.commit('setmasutilizados', parametro.id);
}
};
export default actions;