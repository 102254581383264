const mutations = {
  setFiltersTop(state, filters) {
    state.filtersTop = filters;
  },
  setInfoTable(state, infoTable) {
    state.infoTable = infoTable;
  },
  setDataRow(state, data) {
    state.dataRow = data;
  },
  setNuevasActasFiltersTop(state, filters) {
    state.nuevasActasFiltersTop = filters;
  },
  setNuevasActasInfoTable(state, infoTable) {
    state.nuevasActasInfoTable = infoTable;
  },
  setAfiliadosByQuery(state, afiliados) {
    state.afiliadosByQuery = afiliados;
  },
  setEmpresasByQuery(state, empresas) {
    state.empresasByQuery = empresas;
  },
};

export default mutations;