import axios from "@/axios/axios-api";

const actions = {
  async getPaises() {
    const paises = await axios.get(`api/taxonomy/paises`);

    return paises.data.data;
  },

  async getGmapConfig() {
    const apikey = await axios.get(`api/configuration/gmapConfig`);

    return apikey.data.data;
  },

  async getProvincias() {
    const provincias = await axios.get(`api/taxonomy/provincias`);

    return provincias.data.data;
  },

  async getProvinciasByPaisId(context, paisId) {
    const provincias = await axios.get(
      `api/taxonomy/provinciasByPaisId?paisId=${paisId}`
    );

    return provincias.data.data;
  },

  async getLocalidades() {
    const localidades = await axios.get(`api/taxonomy/localidades`);

    return localidades.data.data;
  },

  async getLocalidadesByProvId(context, provId) {
    const localidades = await axios.get(
      `api/taxonomy/localidadByProvId?provId=${provId}`
    );

    return localidades.data.data;
  },

  async getBarrios() {
    const barrios = await axios.get(`api/taxonomy/barrios`);

    return barrios.data.data;
  },

  async getGecrosVueModules() {
    const gecrosModules = await axios.get("api/taxonomy/GecrosVueModules");

    return gecrosModules.data.data;
  },
  closeLeftMenu(context) {
    context.commit("toggleHelp", { ayudaOpened: true });

  },
  closeHelp(context) {
    context.commit("toggleHelp", { ayudaOpened: false });
  },
  async deleteImgUploaded(context, payload) {
    const response = await axios.delete(`api/archivo/delete-img?asId=${payload}`);
    return response.data
  }
};

export default actions;
