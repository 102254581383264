const helperCUIL = {
  validateCUITCUIL(cuit) {
    if (cuit.length != 13) return false;

    let genero = cuit.split("-")[0];
    let cuitNro = cuit.replaceAll("-", "").split("").map(Number);
    const verificador = cuitNro.pop();
    const codes = [5,4,3,2,7,6,5,4,3,2];

    let multiplicados = cuitNro.map(function(item, index) {
      return item * codes[index]
    });
    let sumaTotal = multiplicados.reduce((a, b) => a + b, 0);
    let resto = sumaTotal % 11;
    let resultado;
    if (resto == 0) {
      resultado = 0;
    }
    else if (resto == 1){
      // Si es hombre: Z=9 y XY pasa a ser 23 *Si es mujer: Z=4 y XY pasa a ser 23
      if (genero == 20) resultado = 9
      else if (genero == 27) resultado = 4
    }
    else {
      resultado = 11 - resto;
    }
    return resultado == verificador;
  },
};

export default helperCUIL;
