const state = {
  user: {},
  modulos: [],
  activeMenu: '',
  activeMenuLevel: [],
  favoritos: [],
  linksAsideBar: [],
  appLoading: false,
  login: null,
  alert: null,
  grupoUsuarios: [],
};

export default state;