const state = {
  filtersTop: [],
  headerTable: null,
  infoTable: null,
  configuracionPrestadoresInfoTable: null,
  configuracionPrestadoresFiltersTop: null,
  ListCheck: null,
  dataRow: null,
  prestadoresByQuery: [],
  origenesByQuery: [],
};

export default state;