import axios from "@/axios/axios-api";
import messages from "@/utils/enums/messages";

const actions = {
  async getMenuApp() {
    const response = await axios.get("api/menuApp");
    return response.data.data;
  },

  async getTabOptions() {
    const response = await axios.get("api/menuApp/TabOptions");
    return response.data.data;
  },

  async updateTabOptions(context, tabOptionDTO) {
    const response = await axios.put("api/menuApp/TabOptions", tabOptionDTO);
    return response;
  },

  async updateMenuApp(context, menuAppDTO) {
    const response = await axios.put("api/menuApp/MenuApp", menuAppDTO);
    return response;
  },

  async generateEnlace(context, payload) {
    const result = await axios.post("api/enlacesutiles", payload.bodyParams);
    context.commit(
      "user/setAlertMessage",
      {
        type:
          result.data.data != null || result.data.data != "undefined"
            ? "success"
            : "error",
        message:
          result.data.data != null || result.data.data != "undefined"
            ? messages.ADD_SUCCESS
            : messages.ADD_ERROR
      },
      { root: true }
    );

    return result.data.result;
  },
  async getEnlacesUtilesInfoTable(context) {
    const result = await axios.get("api/enlacesutiles");
    return result.data.data;
  },

  async getEnlaceById(context, payload) {
    const enlace = await axios.get(
      `api/enlacesutiles/enlaceUtilById?enlaceId=${payload.enlaceId}`
    );
    return enlace.data.data;
  },

  async updateEnlace(context, enlaceDTO) {
    let response = await axios.put("api/enlacesutiles", enlaceDTO);
    context.commit(
      "user/setAlertMessage",
      {
        type:
          response.data.data != null || response.data.data != "undefined"
            ? "success"
            : "error",
        message:
          response.data.data != null || response.data.data != "undefined"
            ? messages.UPDATE_SUCCESS
            : messages.ADD_ERROR
      },
      { root: true }
    );
    return response.data.result;
  },

  async deleteEnlaceById(context, payload) {
    let response = await axios.delete(
      `api/enlacesutiles?enlaceId=${payload.enlaceId}`
    );
    context.commit(
      "user/setAlertMessage",
      {
        type: response.data.data == true ? "success" : "error",
        message:
          response.data.data == true
            ? messages.DELETE_SUCCESS
            : messages.DELETE_ERROR
      },
      { root: true }
    );
    return response.data;
  },

  async getCombos(context) {
    let result = await axios.get("api/enlacesutiles/combotiposenlaces");
    return result.data.data;
  },
  async getApps() {
    let result = await axios.get("api/taxonomy/apps");
    return result.data;
  },
  async listarRegistros(context, payload) {
    const response = await axios.post(
      "api/aplicacionMovil/ListarDispositivo",
      payload
    );
    return response.data;
  },
  async tokenApp(context, payload) {
    const response = await axios.post("api/appBenef/tokenApp", payload);
    return response;
  }
};

export default actions;
