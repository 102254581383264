const permissions = Object.freeze({
  // gestion procesos fiscalizacion
  ENVIAR_ACTA_EMAIL: "50255BF3-20FC-449D-B724-0C622A84DDB3",
  ASIGNAR_INSPECTOR: "8C646312-8055-44CE-8AD7-BBEAD1E8D852",
  EXPORTAR_ACTA_PDF: "7D6D1A55-6978-4741-B158-EB1105DD5246",
  CAMBIAR_ESTADO_ACTA: "BB3AEA60-F79E-4C91-9D4F-74E93796C4A1",
  ELIMINAR_PROCESO_FISCALIZACION: "2C033A3A-E77D-4A7D-9136-7E827170CE2B",
  EDITAR_PROCESO_FISCALIZACION: "9394C5C7-5513-47EA-AB8A-F369F5E2BD10",
  ELIMINAR_ACTA_FISCALIZACION: "D894AC8E-0D9F-4873-B9CB-718961E7EC03",
  NUEVO_PROCESO_FISCALIZACION: "4D16B7C9-9980-4420-9A77-93E25D8FDCB6",
  EDITAR_ACTA_FISCALIZACION: "94258051-93C3-42CE-B3E3-287B3C9BB3D7",
  // abm formato email actas
  EDITAR_FORMATO_EMAIL_ACTA: "D3E64571-F98E-4DAD-AB9E-7A29A9D0BAB5",
  ELIMINAR_FORMATO_EMAIL_ACTA: "7EE7427D-CE5D-444B-8463-1307945B8E89",
  NUEVO_FORMATO_EMAIL_ACTA: "D9E5C185-1A31-4ACE-B74B-89A4FE11F730",
  // abm formato PDF actas
  NUEVO_FORMATO_PDF_ACTA: "3559800E-200C-45C7-8571-7BC710886D6E",
  EDITAR_FORMATO_PDF_ACTA: "A5A56E4A-54C7-40ED-BBD0-F0426004BED9",
  ELIMINAR_FORMATO_PDF_ACTA: "19462094-54FE-42C6-A444-A8ECE6B18888",
  // abm tipo de interes
  EDITAR_TIPO_INTERES: "E1AAA9A9-C531-450B-B4F5-63C319F85AA0",
  ELIMINAR_TIPO_INTERES: "78A10247-AF04-4C11-ABDF-79FE838BA91A",
  NUEVO_TIPO_INTERES: "989328A1-3770-4E26-BFFA-94AE35ECDFE1",
  // abm estados acta
  NUEVO_ESTADO_ACTA: "A73DB285-64D3-4A8A-AD3A-469B51EFE949",
  EDITAR_ESTADO_ACTA: "B2684F81-152C-4387-930A-8E1265509BF6",
  ELIMINAR_ESTADO_ACTA: "A5E486C1-9B32-40BD-B3F5-DEC7D735B20B",
  // informe fiscalizacion AFIP
  NUEVO_INFORME_AFIP: "2BA606DD-3BB2-4FB1-BC78-ED9EE0E37C93",
  ELIMINAR_INFORME_AFIP: "E85E7D29-CD2A-44B4-A780-D5CC457F83DC",
  // abm enlaces utiles
  ELIMINAR_ENLACES_UTILES: "CD3DB9D2-17C7-4F22-BD12-A4D63A7051F2",
  EDITAR_ENLACES_UTILES: "001F0B0D-BDDE-48FD-8B4C-2FD550F4AC4D",
  NUEVO_ENLACES_UTILES: "C8992E5F-034D-49EA-8033-A51C40DA2CFB",
  // menu y tabs
  EDITAR_MENU_TABS: "00942C90-3058-4B59-B08C-EFEAC3696FE6",
  // abm servidores email
  EDITAR_SERVIDORES_EMAIL: "B97E7FC5-9891-441D-B66D-E1F773D3EB5A",
  ELIMINAR_SERVIDORES_EMAIL: "61AF95EE-114B-406E-915D-D0E99856F25E",
  NUEVO_SERVIDOR_EMAIL: "F7A65320-99ED-42C0-BDED-A280C2771E44",
  // config cartilla
  EDITAR_INFO_CARTILLA: "A7260B8D-AA04-479D-9FF3-BE772198484F",
  EDITAR_UBICACION_CARTILLA: "EE4D54C6-4446-43A0-89D7-B0CD5E62BB5F",
  // abm sectores
  NUEVO_SECTOR: "C95ADEBE-651C-4649-BD26-1F69F1F70323",
  EDITAR_SECTOR: "F2A3100A-FAA1-42D3-A2C1-A97F05B5E7D0",
  ELIMINAR_SECTOR: "A6004685-24A6-4937-A4FE-718E86A3B5C5",
  // abm destinos
  NUEVO_DESTINOS: "6E79229F-7D00-43BE-B508-27EC2C8C7630",
  EDITAR_DESTINOS: "56D90E48-4005-41C7-B28F-164D69D2CC19",
  ELIMINAR_DESTINOS: "D21C2ACE-BCE4-4CB9-A1C9-4A4F6B4CB87D",
  // abm parentescos
  NUEVO_PARENTESCO: "C814AE09-44EA-41F0-B3D0-83C2CC6BAAD7",
  EDITAR_PARENTESCO: "19D22795-3791-4139-8DF9-66871B6577E4",
  ELIMINAR_PARENTESCO: "2550AFE6-975D-419E-A57B-4609F787631A",
  // abm botón de pago
  NUEVO_CONFIG_PAGO: "CF80A18E-4443-4E5C-B264-AB9A4D595703",
  EDITAR_CONFIG_PAGO: "CACF9539-A788-4761-B5E6-A620C16D6249",
  ELIMINAR_CONFIG_PAGO: "CF24097F-D6D0-4FB3-83EB-E901EF11A101",
  // lotes de deuda
  NUEVO_LOTE_DEUDA: "13B8B5D0-3209-4FE2-8045-EF66F3FA6B83",
  EDIT_LOTE_DEUDA: "07FD6FE0-A8C9-4A66-8DA1-16CBEC531C0C",
  ELIMINAR_LOTE_DEUDA: "5443012E-44AE-4488-BE03-B5640A506869",
  ENVIAR_LOTE_DEUDA: "4F3435FE-9525-4911-B736-DF9464668211",
  // envios entidades de cobranza
  NUEVO_ENVIO_ENTIDAD: "2F07033E-6390-4260-8C09-F794027FD459",
  EDITAR_ENVIO_ENTIDAD: "C22A53DF-3698-474D-A7BE-71CD63BBEBE0",
  VER_ENVIO_ENTIDAD: "9E6DEF2F-C64B-428E-A2E0-729E6EABB34B",
  ELIMINAR_ENVIO_ENTIDAD: "FA186DB4-9230-4F0F-B803-A136C35019DD",
  // abm formato email app
  EDITAR_FORMATO_EMAIL_APP: "EA6BB4ED-0EC5-4DCD-BBF3-60A32A672ABD",
  ELIMINAR_FORMATO_EMAIL_APP: "50BEE901-20DD-4C32-8785-3FD0D5247F24",
  NUEVO_FORMATO_EMAIL_APP: "DEC9768D-5D3F-4640-9A7C-A7C84D90A562",
  //abm configuracion app
  NUEVO_CONFIGURACION_APP: "D7096581-B01A-454C-B263-42369DF38FC0",
  EDITAR_CONFIGURACION_APP: "0780FA16-BDD4-4F55-958D-32744F92A70A",
  ELIMINAR_CONFIGURACION_APP: "6DFAAEEF-C8AF-41F2-9459-2FB8B3C84D4E",
  //abm tipos de expedientes
  NUEVO_TIPOS_DE_EXPEDIENTES: "0CF77E39-2169-4C96-A0D2-6062D0EDAA74",
  EDITAR_TIPOS_DE_EXPEDIENTES: "B83EC454-B926-4426-8941-3E28199B5126",
  ELIMINAR_TIPOS_DE_EXPEDIENTES: "E98DAC2A-4537-4944-B0B7-0D41B02A58AA",
  //abm gestion de afiliados
  NUEVO_AFILIADO: "A9F6C10C-6216-41D5-97AB-9AF6B36AD6DF",
  EDITAR_AFILIADO: "BE27FB73-CE67-4B28-B9A5-3AF5635A88FF",
  ELIMINAR_AFILIADO: "B7976592-35DE-4523-8470-2B5601B9AB24",
  IR_REFERENCIAS: "F56CA7AD-ED4F-4F32-9BE3-E82BD77DC9A4",
  REGISTRAR_BAJA: "44D78A75-4DD7-4824-AC2E-44FEB3192166",
  REGISTRAR_NUEVO_MOVIMIENTO: "B1E75E2A-F7DE-4EE4-BDD9-F0FA00EC425E",
  VER_AFILIADO: "9C622264-CD15-4886-8815-F033DE3234BF",
  FORZAR_MOVIMIENTO: "AE1BA595-D305-463E-B60B-30EC82BCDF09",
  GESTION_MOVIMIENTOS: "FAD9C349-F997-4C77-BD85-5C94B9DADBB2",
  REGISTRAR_NUEVO_MOVIMIENTO_PLANES_TODOS:
    "035AA140-CD3E-4E96-A1E7-640721063277",
  EDITAR_VENCIMIENTO_CREDENCIAL: "5D414AB7-F104-4659-B1A3-3296978F5C6A",
  GENERAR_TOKEN_AFILIADO: "375CA10F-0BC4-47A3-8FE6-55A8D7FF763C",
  UNIFICACIONES_AFILIADO: "A62EE6D6-2A5C-41F1-8A6A-049CBD980F6D",
  //abm Formas de Pago
  NUEVA_FORMA_PAGO: "F7211AE2-8B27-437D-BB6A-54F8051F3C4E",
  EDITAR_FORMA_PAGO: "7DCF06FE-1495-417A-BE1A-A0F99D6094E6",
  ELIMINAR_FORMA_PAGO: "ECFE3C95-EFFF-40DB-9A9C-626CC7B283A9",
  //abm entidades financieras
  NUEVO_ENTIDAD_FINANCIERA: "C33D8846-0981-4B18-B1AF-734F49CACC2D",
  EDITAR_ENTIDAD_FINANCIERA: "651C104C-FCA5-4E45-B5F2-1DAA7729EDB4",
  ELIMINAR_ENTIDAD_FINANCIERA: "AC72B843-37FC-4FC7-BBA2-26F766AA7EAE",
  // abm generadores de expedientes
  NUEVO_GENERADOR_EXPEDIENTES: "79648D2E-D0EF-41D2-9B6D-C477D709A7C8",
  EDITAR_GENERADOR_EXPEDIENTES: "971EBB3B-D2D4-44AE-B7DC-B65BECE13FB7",
  ELIMINAR_GENERADOR_EXPEDIENTES: "9800D8C4-9532-43F9-BA4A-42C00A34322F",
  NUEVO_TIPO_GENERADOR: "55687FF6-3DF1-42BF-A03D-A6E44F47115B",
  //abm tipos de generadores
  NUEVO_TIPO_GENERADOR_EXPEDIENTES: "10F58968-B775-4813-A835-F9478FADC395",
  EDITAR_TIPO_GENERADOR_EXPEDIENTES: "E8752ABD-C21F-492F-95D9-F90FC538D696",
  ELIMINAR_TIPO_GENERADOR_EXPEDIENTES: "0ACD1630-DE2C-45BB-B1A1-2623B82EF541",
  GO_TO_TIPOS_GENERADORES: "DB2B80C7-55B4-4AB1-A45A-1DCAA7243342",
  //abm Consulta de cobradores
  ELIMINAR_COBRADOR: "6F380C71-72C7-4A2B-A234-3ED4BE791FE9",
  EDITAR_COBRADOR: "4B346EFF-A6ED-4D66-B661-7D39D7B8DC55",
  NUEVO_COBRADOR: "DD1C2733-4AA8-43B7-B06D-A23B23C90027",
  GO_TO_TIPOS_GENERADORES: "DB2B80C7-55B4-4AB1-A45A-1DCAA7243342",
  //abm cajeros
  NUEVO_CAJERO: "3C377484-657B-4EF5-BD92-B570ACE61CC4",
  EDITAR_CAJERO: "71237EEE-EA0E-48B9-96BC-9BFF6765A6B5",
  ELIMINAR_CAJERO: "5104FCD8-A724-49F7-913A-3D2EDC732414",
  //abm condiciones de iva
  NUEVO_CONDICIONES_IVA: "D56141F0-6893-440F-BCD5-355FCF26F73D",
  EDITAR_CONDICIONES_IVA: "63BDDB51-56AB-4178-88AA-98420A8EBC61",
  ELIMINAR_CONDICIONES_IVA: "9B5FEE44-27E7-4F0A-86DD-CC8EA0732E0F",
  //abm Conceptos
  NUEVO_CONCEPTO: "6D1357E3-D160-4391-8102-27927AA90471",
  EDITAR_CONCEPTO: "FBBAB0DA-15A1-4A71-9266-95C1FC180482",
  ELIMINAR_CONCEPTO: "8B04A742-B77C-47AA-A35E-92AEDE0070FF",
  //abm Impuestos
  NUEVO_IMPUESTO: "47F53BDD-B028-4352-BC8F-4FB9586CD7BE",
  EDITAR_IMPUESTO: "04177CAD-0274-47F9-93C5-295A382A833E",
  ELIMINAR_IMPUESTO: "3133FFD9-1CF9-420F-B46C-F6FE351391D3",
  // Gestion agentes cuenta
  NUEVO_AGENTE: "0097E479-C3E7-4123-A27F-AFC0456C6397",
  EDITAR_AGENTE: "57AFB4CC-E2CF-47E9-A829-B71D3C444383",
  ELIMINAR_AGENTE: "FA41757B-0439-40F0-915C-389FBE2C2712",
  FORMAS_PAGO_AGENTE: "C12CEC68-F607-4DC4-80F7-A7213AF64CE7",
  CUENTA_CORRIENTE_AGENTE: "D015307B-11DA-43F2-B65A-30A657A97A73",
  RELACION_GRUPO_FAMILIAR: "15B0AA55-1E81-4822-A2C9-37FBB8F0B16D",
  ALICUOTAS_PERCIBIDAS_AGENTE: "69B221DC-6A35-42B9-A39E-3DB8649B4F9B",
  // Vencimiento cuit
  NUEVO_VENC_IVA: "F4B4664E-29DA-43DE-85E6-1AF4E70B3B52",
  EDITAR_VENC_IVA: "0F644DF6-5AED-4455-8ADB-C9ED138C83CF",
  ELIMINAR_VENC_IVA: "6DB00638-0A37-4ADF-AF8E-2CCCF55C9583",
  // abm tipos de documentos
  NUEVO_TIPO_DOCUMENTO: "6A21368A-CF3E-41A4-8403-83F06ED5AD45",
  EDITAR_TIPO_DOCUMENTO: "79A2674E-2B76-480F-8559-F4248815A971",
  ELIMINAR_TIPO_DOCUMENTO: "770703A8-9F05-4A25-BC45-A434CA1D3DF6",
  //Nacionalidades
  NUEVA_NACIONALIDAD: "8A26548F-717F-45FF-A31B-07AFDB6AC2DB",
  EDITAR_NACIONALIDAD: "024C7C7E-EE36-4C3A-8FC0-EB2ED8CFC17A",
  ELIMINAR_NACIONALIDAD: "B9868A3B-2362-4296-8FE5-7D0EF16F2897",
  // abm Tipos de beneficiarios
  NUEVO_TIPO_BENEF: "63BF8F16-6FAE-49A5-8EAB-F7A23BE4BCB9",
  EDITAR_TIPO_BENEF: "289FBE33-F277-433B-A6C1-23C1A36123A0",
  ELIMINAR_TIPO_BENEF: "BB932F7E-C86E-4229-9501-203B45654AD7",
  //Web Parentescos
  NUEVO_PARENTESCOS: "B4983CDD-C6D2-438C-92D6-F36990B041F7",
  EDITAR_PARENTESCOS: "3ADFFD1E-2C93-4FAC-92FA-2BAC0B732813",
  ELIMINAR_PARENTESCOS: "3F0E2AEB-597E-4768-BD18-8E483AE8082B",
  //Motivos de baja
  NUEVO_MOTIVO_DE_BAJA: "AD4D2992-501E-4A95-A371-85A7AA2E9D18",
  EDITAR_MOTIVO_DE_BAJA: "B8343F27-A0A5-4DBF-8C60-EF45880756D1",
  ELIMINAR_MOTIVO_DE_BAJA: "889EB2DD-CFC5-4D2A-8D35-9EF4853B75A1",
  //Estados Civiles
  NUEVO_ESTADO_CIVIL: "2E49AE72-04EB-4F09-A07C-E3B842854C55",
  EDITAR_ESTADO_CIVIL: "9FCF7BF0-C8FE-4908-ACF3-69AFC3793DAC",
  ELIMINAR_ESTADO_CIVIL: "E0A7B5F4-A044-44F0-8F90-540193D59CA3",
  //abm Dependencias
  NUEVA_DEPENDENCIA: "B2B3E3D2-6E9B-4C1D-97BB-578122C2DEBA",
  EDITAR_DEPENDENCIA: "555925D9-4C48-4618-8935-99D73A4FCB8D",
  ELIMINAR_DEPENDENCIA: "9F5B3A6E-3125-4246-B7E6-9FE826930BCB",
  //ABM Token en autorizaciones de prestadores
  NUEVO_TOKEN: "048C614C-9842-480A-816A-16F641B36C4B",
  EDITAR_TOKEN: "CF55A011-B3B0-43C2-8FAD-7ACE8DBA0647",
  ELIMINAR_TOKEN: "4B979D80-9FCC-423B-8EB7-2AF03965E316",
  HABILITAR_TOKEN: "1ED3287F-63A0-49F1-9CB6-F0A7FBA8FA90",
  //abm Otros tipos de afiliaciones
  NUEVO_TIPO_AFILIACION: "1F93BCC1-0C7B-48F0-95E9-A0F217BCC669",
  EDITAR_TIPO_AFILIACION: "7F20C869-7D04-497F-A5E1-9A7010691634",
  ELIMINAR_TIPO_AFILIACION: "C899C52A-8A19-4B78-829A-AB838DEB6EDC",
  //abm Reglas comerciales
  NUEVA_REGLA_COMERCIAL: "70E555A4-0976-4C58-9EF9-07861195B53E",
  EDITAR_REGLA_COMERCIAL: "A5BBB38F-3DC7-49EC-AFE8-BD008A0643AC",
  ELIMINAR_REGLA_COMERCIAL: "99EE47EA-3FE8-4CE3-9ED9-483427AFC65A",
  VARIABLE_REGLA_COMERCIAL: "722F177D-1B4E-4D2E-801A-AAD6A2C4AB60",
  //abm tipos de comprobantes
  CONFIGURAR_TIPOS_COMPROBANTES: "9FC4A276-FA5E-49C8-9425-FBBB40249C33",
  NUEVO_TIPO_COMPROBANTE: "EDEAF6A4-6E27-481A-AA7F-1D73056B92C3",
  EDITAR_TIPO_COMPROBANTE: "D3E2E75E-37B9-43FD-9584-901920E8F784",
  ELIMINAR_TIPO_COMPROBANTE: "3CC1AAE4-EF04-468F-A2E8-F0C0CDC2C00E",
  COPIAR_TIPO_COMPROBANTE: "8BAFEF45-543B-48B6-8C64-D9B593CEBB9B",
  //abm RNOS
  NUEVA_OS_RNOS: "0D2399C7-75DE-4A3B-B4AB-C35344B78837",
  EDITAR_OS_RNOS: "F1DF895A-DD68-4688-B8D1-67B26D01CEB6",
  ELIMINAR_OS_RNOS: "9FA52DDF-0BF3-4306-90C2-48F1C3A6AF57",
  //abm delegaciones de OS
  NUEVA_DELEGACION: "7533656B-73E2-4D3C-A605-554C3A991391",
  EDITAR_DELEGACION: "01858E9A-A74B-4C09-8DC3-B96B59084E9F",
  ELIMINAR_DELEGACION: "B8F2FCBB-B719-4DB9-A7B7-2C6814E8BE88",
  //abm regimenes de OS
  NUEVO_REGIMEN: "951CB919-2376-4A71-947A-21233E40D5AA",
  EDITAR_REGIMEN: "00B8758C-1B4F-4385-A227-B543EC01AE43",
  ELIMINAR_REGIMEN: "44E790E7-175F-4471-B0B0-8AE68059E95D",
  //abm ddjj y aportes
  CONFIGURACION_DDJJ_APORTES: "F3F38814-C3BF-4DE2-A3AB-9764A6C6AB4C",
  CARGA_APORTES: "FD5DB418-5841-414C-9B35-F5BA0C547D0E",
  CAMBIO_CONVENIO_APORTES: "2354DA36-6B04-4137-93AC-F72494F87905",
  //abm de descuentos en aportes y contribuciones
  NUEVO_DESCUENTO: "BF3F8A8C-2F5B-46F7-9021-3837316B0791",
  EDITAR_DESCUENTO: "813283FD-7ED1-4737-9CBF-77B031620103",
  ELIMINAR_DESCUENTO: "E209EEE2-879B-4F57-BD8B-863342BF4A93",
  //abm de conceptos afip
  NUEVO_CONCEPTO_AFIP: "BDE3176A-FED1-4492-BC93-DCA3139DFF49",
  EDITAR_CONCEPTO_AFIP: "53BEC769-409D-4521-9B88-00E86EF00FBE",
  ELIMINAR_CONCEPTO_AFIP: "84AB4487-D90F-4993-BB45-F42C753BE00C",
  // abm empresas aportantes y sucursales
  NUEVO_EMPRESA: "3CD269DB-5F5A-42E1-802D-748722857E6B",
  EDITAR_EMPRESA: "8257747C-D6AF-44A1-84C0-514377410DF3",
  ELIMINAR_EMPRESA: "F0EDDB09-F12D-4646-B103-09A9B6B0E6CA",
  ACCESO_SUCURSALES: "D6710DA9-F976-4846-B141-171F806268A1",
  // abm sucursales
  NUEVA_SUCURSAL: "7B3F9649-71B7-4E0B-9DBF-893E709BE6FE",
  EDITAR_SUCURSAL: "7062552B-E38B-4838-804E-9F61C9AF9AC1",
  ELIMINAR_SUCURSAL: "8DC048C8-3568-43CC-BA5B-FBFC29FF21E3",
  //abm solicitudes de afiliacion
  NUEVO_TIPO_DE_SOLICITUD: "DAF7ED5F-AF86-4B7E-B1F5-563C68AAFA74",
  EDITAR_TIPO_DE_SOLICITUD: "7741AA64-DC0E-4F8E-9E8B-47DA5DD5EDF3",
  ELIMINAR_TIPO_DE_SOLICITUD: "80A080FA-268C-4782-8A14-03508795B3ED",
  CONFIGURACION_TIPO_DE_SOLICITUD: "74860C50-3E29-4280-8729-F379BB1727BA",

  //abm vendedores
  NUEVO_VENDEDOR: "479C13A1-B4FE-4471-B392-119270C1989C",
  EDITAR_VENDEDOR: "BF1A77A4-4695-4D04-81F3-7BF29650B244",
  ELIMINAR_VENDEDOR: "B5AC466A-0E6D-4163-A8C2-F944AB46D9EF",
  USUARIOS: "22B66693-85EB-456D-9483-683FA2E96A4C",

  //abm RNOS
  NUEVA_OS_RNOS: "0D2399C7-75DE-4A3B-B4AB-C35344B78837",
  EDITAR_OS_RNOS: "F1DF895A-DD68-4688-B8D1-67B26D01CEB6",
  ELIMINAR_OS_RNOS: "9FA52DDF-0BF3-4306-90C2-48F1C3A6AF57",
  //abm delegaciones de OS
  NUEVA_DELEGACION: "7533656B-73E2-4D3C-A605-554C3A991391",
  EDITAR_DELEGACION: "01858E9A-A74B-4C09-8DC3-B96B59084E9F",
  ELIMINAR_DELEGACION: "B8F2FCBB-B719-4DB9-A7B7-2C6814E8BE88",
  //abm regimenes de OS
  NUEVO_REGIMEN: "951CB919-2376-4A71-947A-21233E40D5AA",
  EDITAR_REGIMEN: "00B8758C-1B4F-4385-A227-B543EC01AE43",
  ELIMINAR_REGIMEN: "44E790E7-175F-4471-B0B0-8AE68059E95D",
  //abm topes en remuneraciones
  NUEVA_REMUNERACION: "D01324FD-EA56-4FBF-94DB-C5FFCA272966",
  EDITAR_REMUNERACION: "775E660E-FC12-4171-800B-156DC7DD4227",
  ELIMINAR_REMUNERACION: "F438B92A-4B29-4502-808D-DE3D51A76549",
  //abm Valores cápita anses jubilados
  NUEVA_CAPITA_ANSES_JUBILADOS: "65BFED5C-16E3-40FA-A29B-DDF8842F80FD",
  EDITAR_CAPITA_ANSES_JUBILADOS: "482CE7CF-CD43-4E33-8DAB-7488C2751B0E",
  ELIMINAR_CAPITA_ANSES_JUBILADOS: "3D65DC0B-6BEC-45D1-9DAC-88AF99F2400F",
  //abm remuneraciones mínimas
  NUEVA_TABLA_REMUNERACION_MINIMA: "F3909A94-3C3E-42F2-BCFA-1CE64AB7CC4E",
  EDITAR_TABLA_REMUNERACION_MINIMA: "E3CE067E-65D5-46F3-87CB-9BA879B7CFDD",
  ELIMINAR_TABLA_REMUNERACION_MINIMA: "60E5650F-41ED-4454-91B0-810A626BC046",
  RELACION_REMUNERACIONES_EMPRESAS: "9DA4FD6F-D686-4C0E-8B9B-38D8F24AB3F9",
  REMUNERACIONES_MINIMAS: "CD808C90-DACB-4F3F-AFD3-17C3A14FE0F8",
  //abm Valores cápita anses desempleo
  NUEVA_CAPITA_ANSES_DESEMPLEO: "60D2DE5E-7E4E-49C2-A570-597A0C2A1CA3",
  EDITAR_CAPITA_ANSES_DESEMPLEO: "4522A726-6EC7-4A8B-96D7-04FE717EF57B",
  ELIMINAR_CAPITA_ANSES_DESEMPLEO: "E1566589-7AC0-4A39-8729-EFDE5908A747",
  //abm Tablas SICOSS
  NUEVA_CONFIGURACION_SICOSS: "ED3E5CCB-AE12-4935-8C06-40D4E60B4E9B",
  EDITAR_CONFIGURACION_SICOSS: "D112B7F2-60A3-408C-B5F8-7BA5FAE2E72D",
  ELIMINAR_CONFIGURACION_SICOSS: "C584BD35-B978-45CB-BDE1-E6C1C2D88A62",
  NUEVA_GERENCIADORA_Y_NIVEL_ATENCION: "56D8298C-36FF-49CD-BA45-2B3837A9BE46",
  EDITAR_GERENCIADORA_Y_NIVEL_ATENCION: "CCE31862-D115-444D-B7C2-35B547B2137E",
  ELIMINAR_GERENCIADORA_Y_NIVEL_ATENCION:
    "88A0F479-F054-488C-922B-DD4DA15372DF",
  //abm Procesos de cobranza
  NUEVO_PROCESO_COBRANZA: "3CA2C1DC-245F-48AB-A07F-2F62F3AC5A60",
  EDITAR_PROCESO_COBRANZA: "FBA238C2-306B-4DBA-8C7C-98EDEF536ABC",
  ELIMINAR_PROCESO_COBRANZA: "8CC44553-7332-4C35-B111-3DBD391100E5",
  CONFIG_CONDICION_IVA: "406556B0-8257-4B2E-BC5C-D47AC7CC26F9",
  CONFIG_DATOS_PROCESO: "67948C3E-CF91-4255-A9F4-AED1CF902C9B",
  //abm Conceptos a devengar
  NUEVO_CONCEPTO_A_DEVENGAR: "F2A76CFA-388F-428C-A631-7A22C759D7DE",
  EDITAR_CONCEPTO_A_DEVENGAR: "70919102-4B7B-4542-A28B-A31B98131FF3",
  ELIMINAR_CONCEPTO_A_DEVENGAR: "5811A16D-E60B-4A64-8766-FED45D1D831B",
  ELIMINAR_TODOS: "BA62D03D-69F3-4637-801F-029B02C942ED",
  PROCESOS_DE_IMPORTACION: "880CA8D0-25D6-42D8-8C1F-BA151580EE56",
  PROCESOS_DE_ACTUALIZACION: "2C228B25-36A0-4A74-9B68-D920192F58F1",
  //abm procesar cobranzas
  BUSCAR_DEUDA: "A90D7D79-B09A-4122-8819-77852C86A8AD",
  PROCESAR_COBROS: "69C169B8-C29A-461C-9B4E-14F2968E9CB9",
  AFILIADO_CODIFICADO: "27A2FA31-1624-4FBE-9D58-550F9557554B",
  //abm devengamiento de coseguros
  NUEVO_DEVENGAMIENTO_COSEGURO: "7567A8C7-0A4B-4467-9918-498A779D10D0",
  EDITAR_DEVENGAMIENTO_COSEGURO: "22907498-2534-4133-997F-10C7C345E6C6",
  ELIMINAR_DEVENGAMIENTO_COSEGURO: "4D07A655-EEF7-42E8-B442-F1C51C693F61",
  //ab proceso devengamiento de coseguros web
  NUEVO_DEVENG_COSEGURO: "55C1C4D3-CE93-4D4A-AA1F-5421758301E8",
  ANULAR_PROCESO: "0BCD5FD5-1853-4193-A451-FF6C5692C3AA",
  // abm adicionales extras
  NUEVO_ADICIONAL_EXTRA: "7FD68E69-8B3E-4A80-B143-862521DE6A87",
  EDITAR_ADICIONAL_EXTRA: "A1DD4BC7-8F9D-49F5-BD38-B53673B8A314",
  ELIMINAR_ADICIONAL_EXTRA: "D7F83606-8871-45CA-9241-BD4D79958CC1",
  ACTUALIZACION_ADICIONALES_EXTRA: "98749A36-BC1F-49C4-AAB4-1C2907D5BCBD",
  //Nuevo aumento masivo de aportes esperados
  NUEVO_AUMENTO_APORTE_ESPERADO: "A12841D2-A469-47F3-BC73-BF4C45C6BB09",
  ANULAR_APORTE_ESPERADO: "C93B4C07-2262-4E3F-96EB-43A41333CFFE",
  BUSCAR_APORTES_ESPERADOS: "2DFEF151-D9F6-451F-AD8C-160942784071",
  IMPORTAR_APORTES_ESPERADOS: "EF1AD616-6D6F-45F5-838D-B550FB2B8E8A",
  ANULAR_NUEVO_APORTE_ESPERADO: "358269CB-D0DA-41F5-ABDE-012B56FA0462",

  // abm de importes fijos a devengar por grupo familiar
  NUEVO_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
    "C4F705DD-C5DB-4B63-85EE-B839922407CD",
  EDITAR_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
    "01D6A18F-D7E2-4A0D-A8DB-D0ED4B73DB31",
  ELIMINAR_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
    "FB45CD38-40A4-42C8-8EC1-1D379C0D33FA",
  PROCESOS_ACTUALIZACION_IMPORTE_FIJO: "A2D493E3-0A63-4F8B-88A9-0FD17C0CB9A7",

  //Proceso movimientos masivos
  NUEVO_PROC_MOV_MASIVO: "013ED791-34E1-4FCD-A296-DD93EC085089",
  ANULAR_PROC_MOV_MASIVO: "3B42FCCD-BB3E-4C41-9419-C5D0FF4197C6",
  CONFIGURAR_PROCESO_MOV_MASIVO: "CCDF987B-FE28-4A26-8DFC-C8CA07ABBEB1",
  //Actualizacion de adicionales extra
  NUEVO_PROCESO_ACTUALIZACION_ADICIONALES:
    "8CE1DBD2-17AB-4506-AE6B-5D4A74DD86F2",
  ANULAR_PROCESO_ACTUALIZACION_ADICIONALES:
    "F6883B7F-B9C6-4429-867C-EF2D37BCCB53",
  //Actualizacion de importes fijos a devengar
  NUEVA_ACTUALIZACION_IMPORTES_FIJOS: "40482D74-5368-422F-AC57-DF8E8D1C88EF",
  ANULAR_ACTUALIZACION_IMPORTES_FIJOS: "E476B871-AC88-44D2-93A9-3E2D5A68A253",
  //Consulta de órdenes de pago
  VER_ORDEN_DE_PAGO: "C9F119F1-428F-4F43-ADFE-9A7C90A5048C",
  CONSULTA_PROCESOS_MASIVOS_OP: "FFAD3492-EEDF-49B3-9C6E-B8C53BFAAAEC",
  EXPORTAR_ARCHIVO: "D4612B7B-9891-4B49-AE84-D1247F2ADDD6",
  // Abm formatos de impresión de órdenes de pago
  NUEVO_FORMATO_IMPRESION_ORDENES_PAGO: "9803FB92-1DEC-44FB-AA89-C1BE5F473DA2",
  EDITAR_FORMATO_IMPRESION_ORDENES_PAGO: "FB977971-3BBF-4E8B-A84D-C15CDDB676F8",
  ELIMINAR_FORMATO_IMPRESION_ORDENES_PAGO:
    "75C6B3CD-C951-484D-B1AB-EFE4FA46DB94",
  //Abm de planes
  NUEVO_PLAN: "9B4BAE05-C897-4F74-991A-BCC22C9F201C",
  EDITAR_PLAN: "1B7C424D-0CB8-4862-A2A3-8B812CEAD036",
  CONFIGURAR_PLAN: "3C9CC17C-B583-4D01-BAA4-EB81E62FC8E6",
  ELIMINAR_PLAN: "F0702132-13C8-4EA8-8E90-BFB84E6AE3C7",

  // Abm de configuracion de ingresos para topes en cuotas
  NUEVO_INGRESO_TOPES_CUOTAS: "51F317BC-BAE0-4D98-BB7A-6F1E3457258F",
  EDITAR_INGRESO_TOPES_CUOTAS: "D46ABCE2-8239-49B5-BC9F-9C063CFC1465",
  ELIMINAR_INGRESO_TOPES_CUOTAS: "7AA44F0C-664E-4179-97D0-84C4E338A6B0",
  CONFIG_DDJJ_INGRESOS_TOPES_CUOTAS: "BC91D4EF-4048-4AEC-A301-F23A2B7CC427",
  CONFIG_CONCEPTOS_DDJJ_INGRESOS_TOPES_CUOTAS:
    "D6AD1FDF-1DA0-4FE2-8193-C84C6C422D48",

  //Abm procesos desregulacion
  NUEVO_PROCESO_DESREGULACION: "B4A668A8-4E87-4A3B-86A7-99D9FC84D38D",
  VER_PROCESO_DESREGULACION: "7A3D8FD0-990E-42A6-B28D-6E357ECA336E",
  EDITAR_PROCESO_DESREGULACION: "B44CD33D-8E88-48DA-99AF-DD7DE1D0E544",
  ANULAR_PROCESO_DESREGULACION: "9C7C29FF-3FFF-4F01-9DA6-FA6AF8BA602F",
  CONFIG_CONCEPTO_DESREGULACION: "84BD6385-260E-4C0F-ADB0-0EC517973AD9",
  ABRIR_PROCESO_DESREG: "11C7D613-1193-46E2-BAF2-869D68B06523",
  CERRAR_PROCESO_DESREG: "576B4A94-6069-48FB-A243-C92FF612061E",
  // Configuración de procesos de desregulación
  NUEVA_CONFIG_CONCEPTOS: "FD30D038-A9AB-41AE-AD5D-6873BD531DD2",
  EDITAR_CONFIG_CONCEPTOS: "98C48AE0-ACAA-4CF8-81D3-78BFC6AF9CF9",
  ELIMINAR_CONFIG_CONCEPTOS: "CA12AA5B-8D9F-4FF2-9326-CD6E925AD17B",
  CONFIG_COMISIONES_CONCEPTOS: "CA6FC549-583B-446D-9FE7-C51DE6C6CF1E",
  NUEVA_COMISION_CONCEPTO: "939153F4-DE7A-471D-A9B6-9C161CA53D0C",
  EDITAR_COMISION_CONCEPTO: "C96112C8-E19E-4E68-AF06-57634A8DE251",
  ELIMINAR_COMISION_CONCEPTO: "D1905030-AEF8-4E6E-AF55-29D755646FAB",
  NUEVO_CONCEPTO_PROC_DESREGULACION: "D7AF6361-B26F-4DF1-8EEF-30A57B0F1EBA",
  ELIMINAR_CONCEPTO_PROC_DESREGULACION: "3226E536-38E9-4C64-A53E-40346CD6D9E5",
  //abm grupos de devengamiento por forma de pago
  NUEVO_GRUPO_DEVENGAMIENTO: "70B42A69-2C96-4D23-AAFD-3FC1B6FFBE52",
  EDITAR_GRUPO_DEVENGAMIENTO: "3F1E175F-A090-401B-BD11-169C58D8C696",
  ELIMINAR_GRUPO_DEVENGAMIENTO: "F4AA1403-0713-424C-9A85-4C7C89AE01D1",
  // Abm formatos de email de órdenes de pago
  NUEVO_FORMATO_EMAIL_ORDENES_PAGO: "4EFCDDAB-AFDE-4937-B054-7B8B9052F29E",
  EDITAR_FORMATO_EMAIL_ORDENES_PAGO: "57189BDA-CB98-4459-9FE8-51354E1A6236",
  ELIMINAR_FORMATO_EMAIL_ORDENES_PAGO: "CC925B70-E902-43F6-B6AD-E5A32F76A78F",
  //consulta bajas automaticas
  ANULAR_PROCESO_BAJA_AUTOMATICA: "25A4A1C9-D8CF-448D-BE44-C78012E8DC77",
  CONFIG_BAJAS_AUTOMATICAS: "9FB3FB9E-B60D-494E-A76D-7F519629FF2D",
  // Config bajas automaticas
  CONFIG_BAJAS_AUTOMATICAS_CONVENIOS: "258DDC8D-0ACB-462D-8DFA-7DF84596FD84",
  CONFIG_BAJAS_AUTOMATICAS_PARENTESCOS: "5FA12FFE-3155-4FB4-A356-67DCBFE08255",
  CONFIG_BAJAS_AUTOMATICAS_COBERTURA_ESPECIAL:
    "A97A83D7-36A5-4223-A34E-DA8CE8FE4688",
  //Gestion de procesos de distribucion de aportes web
  NUEVO_PROCESO_DISTRIBUCION_APORTES_WEB:
    "D4A19C90-8A05-4F09-A7CD-AD5ABB5E6F6D",
  ANULAR_PROCESO_DISTRIBUCION_APORTES_WEB:
    "56F1B761-1D19-41AA-A866-F165BA2559AE",
  //Coberturas especiales
  VER_COBERTURAS_ESPECIALES: "1E9FFD34-A92A-43E1-AF31-458E21DCE857",
  TIPOS_COBERTURAS_ESPECIALES: "3765110C-F3BB-40B2-A302-B1DC8626A75F",
  NUEVA_COBERTURA_ESPECIAL: "61155A92-D07A-485D-AF7B-D0FBCC6729FC",
  EDITAR_COBERTURA_ESPECIAL: "AF96C64D-3870-409E-9D20-DC8565D66860",
  ELIMINAR_COBERTURA_ESPECIAL: "95448D88-EC9D-4F06-814A-412061E2B3B2",
  //abm tipo coberturas especiales
  NUEVO_TIPO_COBERTURA_ESPECIAL: "2ED47BA6-0660-4A20-8958-6DFF0B239F1D",
  EDITAR_TIPO_COBERTURA_ESPECIAL: "7BB108C0-F1B6-4169-A2DF-465929A0ABCB",
  ELIMINAR_TIPO_COBERTURA_ESPECIAL: "A0E09F43-3340-4569-B817-848ACEDB5C5B",
  DOCUMENTACION_TIPO_COBERTURA_ESPECIAL: "F4151A53-90DA-4BD9-B192-29F596DD497C",
  //abm coseguro por tipo cobertura especial
  NUEVO_COSEGURO_TIPO_COBERTURA_ESPECIAL:
    "760E71B1-201F-44E8-B321-A7B605C00645",
  EDITAR_COSEGURO_TIPO_COBERTURA_ESPECIAL:
    "A35C4C71-CD8B-46F4-8C6E-CD6BC5E6520F",
  ELIMINAR_COSEGURO_TIPO_COBERTURA_ESPECIAL:
    "B63A95FD-5D75-4A9D-8D78-E7591EE5F5CB",
  //abm tipo prestadores
  NUEVO_TIPO_PRESTADOR: "8A8AC093-5268-42A5-B52E-9875F140D489",
  EDITAR_TIPO_PRETADOR: "FDE550A2-41A2-400F-8D0C-E8C4E0794157",
  CODIGOS_PRESCRIBIBLES: "91B140A9-9699-4298-8C1E-572DE73BC962",
  ELIMINAR_TIPO_PRESTADOR: "F825CEAE-9B23-422F-9798-F9AA5CE241AE",
  //abm codigos prescribibles por tipo prestador
  NUEVO_CODIGO_PRESC: "3A001847-2B39-450F-A01B-AE082F225BCC",
  EDITAR_CODIGO_PRESC: "3AEEB278-70E3-4BBE-8BD8-9EF16C988E03",
  ELIMINAR_CODIGO_PRESC: "693CD186-6987-4A1E-B888-C3175DE49068",
  //abm especialidades de prestadores
  NUEVA_ESPECIALIDAD: "68D4BA07-95C4-4B44-87DC-63CA5863DAEB",
  EDITAR_ESPECIALIDAD: "7B8D6660-9F52-43FC-A6A6-CBAA9D384E82",
  ELIMINAR_ESPECIALIDAD: "8D8C6AC8-3ABB-4E8C-8A86-6E537645ED64",
  // DDJJJ de Ingresos para Topes en Cuotas
  IMPORTAR_DDJJ_INGRESOS_TOPES_CUOTAS: "E2B99FD2-EC32-47CF-A50F-50B968B47620",
  ELIMINAR_DDJJ_INGRESOS_TOPES_CUOTAS: "C5A290C5-C61B-4486-A385-2A010DEBBB7A",
  // ABM consulta procesos masivos OP
  VER_DETALLE_PROCESO_MASIVO_OP: "6C1397C0-9656-44E9-B849-F5C1CFAB689F",
  REENVIAR_PROCESO_MASIVO_OP: "BF46509D-4C99-48F6-8762-8F63D9FC3E8C",
  ANULAR_PROCESO_MASIVO_OP: "B188DB99-4268-4BE2-9545-0B1812CBD4A2",
  //ABM Relacion convenio-empresas
  EDIT_RELACION_CONVENIO_EMPRESA: "D6284D7B-34E1-4624-9069-B3B50D0FEB62",
  // ABM formatos exportacion transferencias
  NUEVO_FORMATO_EXPORTACION_TRANSFERENCIA:
    "1A3A5891-30AC-4683-956A-1B75934A16DF",
  EDITAR_FORMATO_EXPORTACION_TRANSFERENCIA:
    "5FC222F0-1EAD-4E4B-B703-ECEF7A7B418A",
  ELIMINAR_FORMATO_EXPORTACION_TRANSFERENCIA:
    "673EF5C5-2579-4740-975B-C6029DE23365",
  //documentacion cobertura
  ADJUNTAR_ARCHIVO: "60E7545C-E21D-4CF5-A109-8740656B93A1",
  VER_ARCHIVOS_ADJ: "C3AC5207-4006-4A31-BFDE-0733E6235EC3",
  DELETE_ARCHIVOS: "4A9DCF00-7764-4A01-8CCA-FF36D0A2BB43",
  EDITAR_DOCUMENTACION: "28A244F9-21EF-4C0F-90B6-F4D880A19382",
  // ABM Procesos de actualizacion de tablas de facturacion
  NUEVO_PROCESO_TABLA_FACTURACION: "E9674BFD-D118-454A-B720-6420391384B3",
  REVERTIR_PROCESO_TABLA_FACTURACION: "61558FA0-4C1D-4BFB-87E2-D5027EAE84E4",
  VER_DETALLE_PROCESO_TABLA_FACTURACION: "7BE57C2A-916C-470D-B66A-A4C788708F64",
  //ABM formato de impresion de cotizador
  NUEVO_FORMATO_IMPRESION_COTIZADOR: "C2608924-0F2C-49C3-A151-4D55F1AE030B",
  EDITAR_FORMATO_IMPRESION_COTIZADOR: "AA868C63-CCF9-4410-A664-D2F6544A0C35",
  ELIMINAR_FORMATO_IMPRESION_COTIZADOR: "D68B8ADD-9BE3-4D36-A887-2C56592C8FA3",

  //ABM formato de email de cotizador
  NUEVO_FORMATO_EMAIL_COTIZADOR: "3D478768-517B-4FC0-8BF7-32AA9DE62AC3",
  EDITAR_FORMATO_EMAIL_COTIZADOR: "FD65EDEA-35BC-442A-AC34-5D2597662FC9",
  ELIMINAR_FORMATO_EMAIL_COTIZADOR: "96B21669-7BE8-4CE1-B111-DFD9C5526A43",
  //ABM COTIZADOR
  NUEVO_COTIZADOR: "F0FA9F68-F94C-4509-BFD6-1773C63F1D15",
  EDITAR_COTIZADOR: "2D042E64-776A-40BB-B5F3-BC8632B80DAB",
  ELIMINAR_COTIZADOR: "71678B53-5918-4443-8485-06EEB224A68A",
  CONFIGURACION_COTIZADOR: "CFD6ADD2-A253-4C52-A2A7-16D1EDEBC18D",
  //ABM GRUPOS ENTIDADES EFECTORAS
  NUEVO_GRUPO_ENTIDADES_EFECTORAS: "F3CC1DA6-5955-474B-A150-C0D718824D47",
  EDITAR_GRUPO_ENTIDADES_EFECTORAS: "459A5FF7-9F31-4481-B75E-784BC09C3700",
  ELIMINAR_GRUPO_ENTIDADES_EFECTORAS: "B9A5F469-9A4F-4841-AFA5-9D873AA423C1",
  IMPORTAR_ENTIDADES_EFECTORAS: "A01421F9-3521-4FFA-BBEC-9C7F46A40DCA",
  //ABM Agrupaciones
  AGRUPACIONES: "7A21A7CC-EC88-4AD8-9325-F94B4F7BE44E",
  NUEVA_AGRUPACION: "A6EF80F1-E270-41E5-AB6C-E33671A2DB5B",
  EDITAR_AGRUPACION: "6FF718BB-F440-4355-A5CB-80AD56559012",
  ELIMINAR_AGRUPACION: "83E0B581-EF52-4FFE-8A93-1F103E022344",
  //ABM configuracion general tableros ANALYTICS
  NUEVO_TABLERO_ANALYTICS: "ABD0E59C-7D79-48D9-8DA5-755CD9255055",
  EDITAR_TABLERO_ANALYTICS: "71E98A2E-D658-4D7C-A3E0-D72682FF4BBF",
  ELIMINAR_TABLERO_ANALYTICS: "866FCD30-723D-45BF-B5F8-88C31ABF49F5",
  CONFIGURAR_API_ANALYTICS: "D7FDD8C8-0D98-4151-80F7-4906BB627789",
  CONFIGURAR_ANALYTICS_AFILIACIONES: "66DF3DA3-ACF4-443C-AE85-4DE3D4531033",
  RECARGAR_ANALYTICS_AFILIACIONES: "B709B580-7411-491C-9B88-D7B1D48602A1",
  CONFIGURAR_ANALYTICS_PRESTACIONES: "43CA82B3-4514-46F1-B89E-C04B193D6D9B",
  RECARGAR_ANALYTICS_PRESTACIONES: "A5C050C5-DF0D-47EF-89E0-5188E00B4113",
  CONFIGURAR_ANALYTICS_INGRESOS: "F866AAAC-990C-4E4F-AAAE-D7CE2A246EAF",
  RECARGAR_ANALYTICS_INGRESOS: "14170818-4C2D-403E-9CDF-81EFBCAA2F52",
  //ABM MONEDAS EN ADMI-SISTEMA
  NUEVA_MONEDA: "46730BE9-148E-4943-8BBF-776D88580F33",
  EDITAR_MONEDA: "26E6B751-6FC9-457F-9CD0-7D4E0D895CC8",
  ELIMINAR_MONEDA: "CEFE1F4C-2CF1-4A58-8136-F1BAAE560565",
  //ABM COTIZACIONES MONEDAS EN ADMI-SISTEMA
  NUEVA_COTIZACION: "BCD219B1-68C8-4364-BEC7-73C958D54F10",
  EDITAR_COTIZACION: "49F5AECD-D831-43B7-B0FD-0AD9DD16E0D0",
  ELIMINAR_COTIZACION: "4F8B9E64-F4F6-4CA0-A14F-50B197E0CD3E",
  //ABM DE USUARIOS
  NUEVO_USUARIO: "3C3136F7-6FCF-41B3-B719-0F988853B21A",
  EDITAR_USUARIO: "7FEDD426-8995-414F-B767-5AFE821F060D",
  CAMBIAR_ESTADO: "82F4E8C2-6DBB-480C-AE12-31078A420B1F",
  RESTAURAR_CONTRASEÑA: "86EAC8DE-1B2A-4A7C-ADC0-4A4134F97AE1",
  ELIMINAR_USUARIO: "2BB974AF-80E2-49EE-8C5D-7B3F13B77D36",
  //ABM TOPES GENERALES
  NUEVO_TOPE_GRAL: "FDF85657-27A5-45FC-9209-DDF4AE1B861D",
  EDITAR_TOPE_GRAL: "007961B9-1756-446C-96BF-BC3331343C13",
  ELIMINAR_TOPE_GRAL: "BA21EAA6-F595-4ECF-AD98-C32084D826F3",
  //PROCESOS IMPORTACION TOPES GRALES
  IR_A_PROCESO_IMPORTACION_TOPES: "CCBA3707-D155-4119-A051-51EA55A6E25C",
  ANULAR_PROCESO_IMPORTACION_TOPE: "A1D146E5-0835-4160-97FC-14AB11C9CE2E",
  NUEVO_PROCESO_IMPORTACION_TOPE: "2783983B-C4F6-4738-8818-8A09B3927BD0",
  //ABM PROCESO ACT ALFA BETA
  NUEVO_PROCESO_ALFA_BETA: "C269374F-D2D0-4851-92FC-42238BE5277B",
  ANULAR_ALFA_BETA: "B6393EAF-229F-4CC4-920E-17392F39AF67",
  CONFIG_ALFA_BETA: "2CC37ABF-9858-4137-BA37-A5BF3EA6F173",
  //CONFIG PLANTILLA CARGA TRAMITES
  CONFIG_PLANTILLA_CARGA_TRAMITES: "19C229AC-61AD-42BE-A51F-F5565D478F91",
  //ABM CONFIG CARGA TRAMITES
  CONFIGURAR_CARGA_TRAMITES: "D795157D-257D-40E9-8D53-3D56FF2B2E48",
  NUEVA_CONFIG_CARGA: "ADB343C0-06C2-410F-ACFD-6C02273E7857",
  EDITAR_CONFIG_CARGA: "9846A8F7-D485-4C08-9689-15A30EF49766",
  ELIMINAR_CONFIG_CARGA: "1802F18B-EEC7-4895-9BFC-46CDF8C96D3C",
  //ABM importacion de factura
  IMPORT_FACURA: "419CBF77-57D3-46D4-902A-40CF16A1F3C6",
  NUEVA_IMPORTACION_FACTURA: "8079530C-6DD5-4923-A083-4318322F3215",
  ANULAR_IMP_FACTURA: "100EE22A-A3DB-46D6-A768-4ABA668BF2D0",
  //ABM configuración de replica
  NUEVA_CONFIG_REPLICA: "BA0170CE-799D-484B-8DCB-2A895E3BEBAE",
  EDITAR_CONFIG_REPLICA: "1BD87743-5EC6-4E74-9941-E608B5DF41A8",
  ELIMINAR_CONFIG_REPLICA: "A0F9BB98-3AAF-40F7-9CD7-99E45B485CB3",
  CONFIGURACION_REPLICA: "DB2D2B3C-15F8-40A7-B378-1BFF196A6D44"
});
export default permissions;
