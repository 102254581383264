const buttons = Object.freeze({
  ACEPTAR: 'Aceptar',
  ELIMINAR: 'Eliminar',
  CONFIRMAR: 'Confirmar',
  CANCELAR: 'Cancelar',
  CERRAR: 'Cerrar',
  CONTINUAR: 'Continuar',
  ANULAR: 'Anular'
});

export default buttons;