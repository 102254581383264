const getters = {
    filtersTop: state => state.filtersTop,
    infoTable: state => state.infoTable,
    configuracionPrestadoresFiltersTop: state => state.configuracionPrestadoresFiltersTop,
    configuracionPrestadoresInfoTable: state => state.configuracionPrestadoresInfoTable,
    ListCheck: state => state.ListCheck,
    dataRow: state => state.dataRow,
    prestadoresByQuery: state => state.prestadoresByQuery,
    origenesByQuery: state => state.origenesByQuery
};

export default getters;