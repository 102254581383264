const codOperacion = Object.freeze({
  NOTA_CREDITO_VENDEDOR: 'ENCV',
  AUTORIZACION: 'AUTO',
  COMPROBANTE_DEVENGAMIENTO: 'DVTO',
  CREAR_USUARIO: 'APPB_CU',
  RECUPERAR_PASS: 'APPB_RC',
  NOTIFICACIONES_AFILIADO: 'ENVIANOV',
  ACTA_FISCALIZACION: 'ACTAFISC',
});

export default codOperacion;