import axios from "@/axios/axios-api";
import router from "@/router";
import helper from "../../../utils/helpers";

const favourites = [];
let tokenTimeOut;

async function getFavourite(obj) {
  obj.forEach(item => {
    if (item.favourite) {
      favourites.push(item);
    }
    if (item.children && item.children.length > 0) {
      getFavourite(item.children);
    }
  });
}
async function setLinks(obj) {
  obj.externalLink = `${window.location.origin}/redirto?id=${obj.path}`;
  if (obj.children?.length) {
    obj.children.forEach(async child => {
      await setLinks(child);
    });
  }
}

const actions = {
  async getModulos(context) {
    context.dispatch("changeAppLoading", {
      status: true,
      text: "",
      opaque: true
    });

    const { options } = (await axios.get("api/Menu/options")).data;
    options.forEach(async obj => {
      obj.showMenu = false;
      await setLinks(obj);
    });
    if (favourites.length == 0) await getFavourite(options);
    context.commit("setFavoritos", favourites);
    context.commit("setModulos", options);
    context.dispatch("changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },

  changeAppLoading({ commit }, val) {
    commit("setAppLoading", val);
  },
  changeActiveMenu({ commit }, val) {
    commit("setActiveMenu", val);
  },
  changeActiveMenuLevel({ commit }, conf) {
    commit("setActiveMenuLevel", conf);
  },
  async setLogin(context, userData) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userName", userData.user);
    urlencoded.append("password", userData.password);
    urlencoded.append("grant_type", userData.grand_type);
    urlencoded.append("client_id", userData.client_id);
    try {
      const result = await axios.post("/connect/token", urlencoded);
      if (result.data.error) {
        setTimeout(() => {
          context.commit("setLogin", null);
        }, 3000);
      } else {
        localStorage.setItem("token", result.data.access_token);
        //si el usuario se autentica con la API
        //utilizo ese token para autenticarse en el MVC
        if (process.env.NODE_ENV != "development") {
          context.dispatch("authenticateOnMvc");
        }
      }
      context.commit("setLogin", result.data);
    } catch (error) {
      if (error?.response?.status === 400) {
        context.commit("setLogin", { validationError: true });
      } else {
        context.commit("setLogin", { error: true });
      }
      setTimeout(() => {
        context.commit("setLogin", null);
      }, 5000);
    }
  },
  async setUser(context) {
    const result = await axios.get("/api/sysuser/profile");
    if (result) {
      context.commit("setUser", result.data);
    }
  },
  async validateUser(context, payload) {
    const response = await axios.get(
      `api/sysUser/validateuser/${payload.username}/${payload.password}`
    );
    return response.data;
  },
  async validateTokenMacenaUser(context, payload) {
    const response = await axios.get(`api/sysUser/validateToken/${payload}`);
    return response.data.data;
  },
  async saveLoginMacenaWithToken(context, payload) {
    const response = await axios.post(
      `api/sysUser/loginMacenaWithToken/${payload.token}/${payload.userName}/${payload.isCompanyUser}`
    );
    return response.data.data;
  },
  async userRequireTwoFactorAuthentication(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/user-require-2fa/${payload}`
    );
    return response.data.data;
  },
  async generateUserQR(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/generate-qr/${payload}`,
      { responseType: "arraybuffer" }
    );
    const blob = new Blob([response.data], { type: "image/png" });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  },
  async verifyTwoFactorAuthentication(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/verify-2fa/${payload.userId}/${payload.code}`
    );
    return response.data.data;
  },
  async authenticateOnMvc(context) {
    try {
      context.dispatch("changeAppLoading", {
        status: true,
        text: "Autenticando usuario...",
        opaque: true
      });
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "POST",
        headers: myHeaders
      };
      const response = await fetch(
        `${window.location.origin}/authenticated`,
        requestOptions
      );
      if (response.status == 200) {
        let minutos = await response.json();
        if (minutos) {
          tokenTimeOut = setTimeout(() => {
            context.dispatch("setLogoutMvc", () => {
              localStorage.removeItem("token");
              router.push("/login");
            });
          }, minutos * 60 * 1000);
        }
        context.dispatch("getModulos");
      } else {
        context.commit("setLogin", { error: true });
        setTimeout(() => {
          context.commit("setLogin", null);
        }, 3000);
      }
    } catch (error) {
      context.commit("setLogin", { error: true });
      setTimeout(() => {
        context.commit("setLogin", null);
      }, 3000);
    }
  },
  async setLogoutMvc(context, callBack) {
    context.commit("setModulos", []);
    context.dispatch("changeAppLoading", {
      status: true,
      text: "Cerrando Gecros...",
      opaque: true
    });

    try {
      if (document.cookie) {
        //me fijo si existe la cookie antes de eliminarla
        const cookie = helper.getCookie("gecrosgestioncookie");
        if (cookie) {
          //elimino la cookie desde el MVC
          const requestOptions = {
            method: "POST"
          };

          const response = await fetch(
            `${window.location.origin}/cerrarsesion`,
            requestOptions
          );
          if (response.status == 200) {
            //ejecuto el callback
            callBack();
          } else {
            //esto lo dejo asi porque a veces .net no elimina la cookie cuando esta expira
            callBack();
          }
        } else {
          //si no existe la cookie ejecuto el callback
          callBack();
        }
      } else {
        //si el navegador no soporta document.cookie ejecuto el callback
        callBack();
      }
    } catch (error) {
      alert(error);
    }
    if (tokenTimeOut) clearTimeout(tokenTimeOut);

    setTimeout(() => {
      context.dispatch("changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
    }, 3000);
  },
  async updateFrequency(context, params) {
    //updetear como visitado
    const option = await axios.post(`api/menu/options/${params.id}`);
    //me fijo si la vista tiene acciones
    //si tiene me fijo si ya existe algo en el localStorage para borrarlo
    const actions = localStorage.getItem("allowedActions");
    if (actions) {
      localStorage.removeItem("allowedActions");
    }
    localStorage.setItem("allowedActions", JSON.stringify(option.data.actions));
  },
  async updateFrequencyByOptionCode(context, code) {
    //updetear como visitado cdo se ingresa por url y no por navegacion
    const option = await axios.post(`api/menu/options?code=${code}`);
    // si hay acciones en localStorage, se pisan con las permitidas para esa opcion y usuario
    const actions = localStorage.getItem("allowedActions");
    if (actions) {
      localStorage.removeItem("allowedActions");
    }
    localStorage.setItem(
      "allowedActions",
      JSON.stringify(option.data?.children)
    );
  },
  async goToMvc(context, params) {
    context.dispatch("changeAppLoading", {
      status: true,
      text: ``,
      opaque: true
    });
    try {
      //me fijo si existe la cookie antes de ir al MVC
      if (document.cookie) {
        const cookie = helper.getCookie("gecrosgestioncookie");
        if (cookie) {
          router.push({
            name: "mvc",
            params: { url: encodeURIComponent(params.url) }
          });
        } else {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      } else {
        window.location.href = `/mvc/${encodeURIComponent(params.url)}`;
      }
    } catch (error) {
      alert(error);
      setTimeout(() => {
        context.dispatch("changeAppLoading", {
          status: false,
          text: "",
          opaque: false
        });
      }, 3000);
    }
  },
  setAlert(context, alert) {
    context.commit("setAlertMessage", alert);
  },
  async grupoUsuarios(context) {
    let dataResponse = await axios.get("api/sysuser/groups");
    context.commit("setGrupoUsuarios", dataResponse.data);
  },
  async getOpcionesSearch(context, payload) {
    let response = await axios.get(`api/Menu/optionSearch/${payload}`);
    return response.data;
  },
  async getUserPassStatus(){
    const result = await axios.get(`api/sysuser/validatePass`);
    return result.data
  },
};

export default actions;
