import prestadores from './prestadores';
import cuotas from './cuotas';
import adminSistema from './administracion-sistema/index';
import email from './email/index';

export default {
  prestadores,
  cuotas,
  adminSistema,
  email,
};